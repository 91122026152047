import { SVGProps } from 'react'

export default function Search(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6.19531 11.5C6.8151 11.5 7.39583 11.3828 7.9375 11.1484C8.48438 10.9089 8.96354 10.5833 9.375 10.1719C9.78646 9.76042 10.1094 9.28385 10.3438 8.74219C10.5833 8.19531 10.7031 7.61198 10.7031 6.99219C10.7031 6.3776 10.5833 5.79948 10.3438 5.25781C10.1094 4.71094 9.78646 4.22917 9.375 3.8125C8.96354 3.39583 8.48438 3.07292 7.9375 2.84375C7.39583 2.60938 6.8151 2.49219 6.19531 2.49219C5.57552 2.49219 4.99479 2.60938 4.45312 2.84375C3.91146 3.07292 3.43229 3.39583 3.01562 3.8125C2.60417 4.22917 2.28125 4.71094 2.04688 5.25781C1.8125 5.79948 1.69531 6.3776 1.69531 6.99219C1.69531 7.61198 1.8125 8.19531 2.04688 8.74219C2.28125 9.28385 2.60417 9.76042 3.01562 10.1719C3.43229 10.5833 3.91146 10.9089 4.45312 11.1484C4.99479 11.3828 5.57552 11.5 6.19531 11.5ZM6.19531 13.1484C5.35156 13.1484 4.55729 12.9896 3.8125 12.6719C3.07292 12.349 2.41927 11.9062 1.85156 11.3438C1.28906 10.776 0.846354 10.1224 0.523438 9.38281C0.205729 8.63802 0.046875 7.84115 0.046875 6.99219C0.046875 6.14844 0.205729 5.35417 0.523438 4.60938C0.846354 3.86458 1.28906 3.21094 1.85156 2.64844C2.41927 2.08594 3.07292 1.64583 3.8125 1.32812C4.55729 1.00521 5.35156 0.84375 6.19531 0.84375C7.04427 0.84375 7.83854 1.00521 8.57812 1.32812C9.32292 1.64583 9.97656 2.08854 10.5391 2.65625C11.1068 3.21875 11.5495 3.8724 11.8672 4.61719C12.1901 5.35677 12.3516 6.14844 12.3516 6.99219C12.3516 7.84115 12.1901 8.63802 11.8672 9.38281C11.5495 10.1224 11.1068 10.776 10.5391 11.3438C9.97656 11.9062 9.32292 12.349 8.57812 12.6719C7.83854 12.9896 7.04427 13.1484 6.19531 13.1484ZM13.8203 15.8828C13.6641 15.8828 13.513 15.8568 13.3672 15.8047C13.2266 15.7526 13.099 15.6693 12.9844 15.5547L8.91406 11.4766L10.5703 9.85938L14.625 13.9219C14.7396 14.0312 14.8229 14.1562 14.875 14.2969C14.9271 14.4375 14.9531 14.5833 14.9531 14.7344C14.9531 14.9479 14.9036 15.1406 14.8047 15.3125C14.7057 15.4844 14.5703 15.6224 14.3984 15.7266C14.2266 15.8307 14.0339 15.8828 13.8203 15.8828Z"
      />
    </svg>
  )
}
