import { styled } from '@stitches/react'

export const Cookie: any = styled('div', {
  pointerEvents: 'auto',
  position: 'fixed',
  right: '0',
  bottom: '0',
  left: '0',
  zIndex: 9999,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  maxWidth: '500px',
  margin: '0 auto',
  padding: '1.5rem',
  textAlign: 'center',
  display: 'flex',
  variants: {
    hide: {
      true: {
        display: 'none',
      },
    },
  },
})

export const BottomBanner: any = styled('div', {
  position: 'relative',
  width: '100%',
  borderRadius: 'var(--radii-lg)',
  color: '#fff',
  background: '#000',
  '&::before': {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    zIndex: -1,
    borderRadius: 'inherit',
    boxShadow: '4px 12px 40px 6px rgba(0, 0, 0, 0.2)',
    content: "''",
  },
  a: { margin: '3px', color: 'var(--gray-50)' },
})

export const Content: any = styled('div', {
  boxSizing: 'border-box',
  padding: '30px 30px 15px',
  border: '0px solid #e5e7eb',
  fontWeight: 500,
  fontSize: '0.875rem',
  textAlign: 'center',
})

export const buttonCSS = {
  display: 'block',
  width: '100%',
  marginTop: '10px',
  padding: '20px',
  border: 'none',
  borderRadius: '0 0 12px 12px',
  color: '#fff',
  fontWeight: 600,
  fontSize: '15px',
  lineHeight: 1.5,
  textAlign: 'center',
  background: '#222',
  outline: 'none',
  cursor: 'pointer',
  appearance: 'none',
  '&:hover': { background: '#444' },
}
