import { ApiConfig, ApiOptions } from '@momenthouse/types'
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { forEach, split } from 'lodash'

import { apiEndpoints } from './endpoints'
import CRUD_API_HOST from './env'
import { loadToken } from './helpers'

export const endpoints = apiEndpoints

export type EndpointName = keyof typeof endpoints

export type IApiConfig = ApiConfig & { [key: string]: string }

export type APIConfigMap<C extends EndpointName> =
  (typeof endpoints)[C]['config']['apiConfig']

export type APIResMap<C extends EndpointName> =
  (typeof endpoints)[C]['config']['response']

export type APIResponse<R> = R extends EndpointName
  ? APIResMap<R> & { error?: string }
  : R

export type APIEndpointParam<E> = E extends undefined
  ? EndpointName
  : E extends EndpointName
  ? E
  : EndpointName

/**
 * API config param
 *
 * {
 *    worldId,
 *    data: {
 *      additionalParams: "additionalParams"
 *    }
 * }
 */
export type APIConfigParam<C, B> = C extends EndpointName ? APIConfigMap<C> : B

export type APIAxiosResponse<R> = AxiosResponse<APIResponse<R>>

const BaseApiUrl = CRUD_API_HOST

export const axiosAPI: AxiosInstance = axios.create({
  baseURL: BaseApiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const getCrudHost = () => BaseApiUrl

/**
 * get endpoint url
 * @param endpointName endpoint name
 * @param config  config context where to map paths
 * @returns endpoint url
 */
export const getEndpointUrl = <E extends EndpointName>(
  endpointName: E,
  config?: APIConfigMap<E>
): string => {
  let url = endpoints[endpointName]?.url as string

  // get all keys from endpoint url
  const keys = split(url, '/').filter((key: string | string[]) => {
    const index = key.indexOf(':')
    if (index > -1) return true
    return false
  })

  forEach(keys, (key: string) => {
    const tempKey = key.replace(':', '')
    // @ts-ignore TODO (temp ignore for mobile strictMode)
    const newUrl = url.replace(key, config[tempKey])
    url = newUrl
  })

  return url
}

const EndpointBuilder = <C extends ApiConfig>(
  endpointName: EndpointName,
  config: C
) => {
  const endpoint = { ...endpoints[endpointName], ...config }
  let { url } = endpoint

  // get all keys from endpoint url
  const keys = url.split('/').filter((key: string | string[]) => {
    const index = key.indexOf(':')
    if (index > -1) return true
    return false
  })

  keys.forEach((key: string) => {
    const tempKey = key.replace(':', '')
    // @ts-ignore TODO (temp ignore for mobile strictMode)
    const newUrl = url.replace(key, config[tempKey])
    // @ts-ignore
    url = newUrl
    // @ts-ignore TODO (temp ignore for mobile strictMode)
    delete endpoint[tempKey]
  })

  endpoint.url = url

  return endpoint
}

const Fetch = async <R, C extends IApiConfig = any>(
  endpoint: C
): Promise<AxiosResponse<R>> =>
  new Promise((resolve, reject) => {
    try {
      // @ts-ignore
      axiosAPI({ ...endpoint })
        .then((response) => resolve(response))
        .catch((e) => resolve(e.response))
    } catch (e) {
      reject(e)
    }
  })

const loadAPIToken = async (useToken = true) => {
  if (useToken) {
    let token: null | string = null
    if (typeof window !== 'undefined') {
      token = localStorage.getItem('jwt')
    }
    if (!token) {
      token = await loadToken()
    }
    axiosAPI.defaults.headers.common.Authorization = `Bearer ${token}`
  } else delete axiosAPI.defaults.headers.common.Authorization
}

/**
 * api caller
 * @param endpointName endpoint name
 * @param config axios config plus custom api configs
 * @param options addition api options
 * @returns
 */
export const API = async <E, C extends ApiConfig>(
  endpointName: APIEndpointParam<E>,
  config: APIConfigParam<E, C>,
  /**
   * {
   *    worldId,
   *    data: {
   *      additionalParams: "additionalParams"
   *    }
   * }
   */
  options?: ApiOptions & AxiosRequestConfig
): Promise<APIAxiosResponse<E>> => {
  await loadAPIToken(options?.useToken)

  const endpoint = EndpointBuilder(endpointName, config)

  return Fetch<APIResponse<E>>(endpoint)
}

export default API
