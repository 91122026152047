import { ArtistBrandProps } from '.'
import { UserPublic } from './api'
import { WorldAssetProps } from './worlds'

export const AVATAR_GENDER_MORPH_TARGET_MAP = {
  male: 0,
  female: 1,
}

export interface EncodedAvatarConfig {
  avatarAttributes: AvatarFormAttributes
  emotes?: {
    [key: number]: string // AvatarItemProps.id
  }
  morphTargets?: AvatarMorphTargets
  wearables: {
    [key in WearableCategory]: string // WearableProps.id
  }
}

export interface AvatarProps {
  avatarAttributes: AvatarAttributes
  emotes?: AvatarEmotes
  morphTargets?: AvatarMorphTargets
  wearables: AvatarWearables
}

export interface AvatarAttributes {
  body: AvatarItemProps
  eyes: AvatarItemProps
  eyebrowColor: string // color hex
  hairColor: string // color hex
  skinTone: AvatarItemProps
}

export type AvatarEmotes = {
  [key: number]: AvatarItemProps
}

export type AvatarMorphTargets = {
  gender: 'male' | 'female'
}

export type AvatarWearables = {
  [key in WearableCategory]: WearableProps
}

export interface AvatarFormProps {
  avatarAttributes: AvatarFormAttributes
  morphTargets: AvatarMorphTargets
  wearables: AvatarFormWearables
}

export interface AvatarFormAttributes {
  body: string // AvatarItemProps.id
  eyes: string // AvatarItemProps.id
  eyebrowColor: string // color hex
  hairColor: string // color hex
  skinTone: string // AvatarItemProps.id
}

export type AvatarFormWearables = {
  [key in WearableCategory]: {
    id: string // WearableProps.id
    parentId: string // WearableProps.parentId
  }
}

export interface AvatarModelProps {
  assetId?: string // AvatarItemProps.id
  asset?: WorldAssetProps
}

export interface AvatarSkinToneProps {
  bodyId?: string // AvatarItemProps.id
  bodySkinAsset?: WorldAssetProps
  headId?: string // AvatarItemProps.id
  headSkinAsset?: WorldAssetProps
  color?: string // color hex
  price?: number
  cap?: number
}

export interface AvatarEyesProps {
  assetId?: string // AvatarItemProps.id
  asset?: WorldAssetProps
  color?: string // color hex
  price?: number
  cap?: number
}

export interface AvatarAnimationProps {
  assetId?: string // AvatarItemProps.id
  asset?: WorldAssetProps
  animationAction?: string // name of animation action
  price?: number
  cap?: number
}

export interface AvatarItemProps {
  id: string
  allowedUserIds?: string[]
  allowedUsers?: Pick<UserPublic, 'id'>[]
  displayName: string
  isPublic?: boolean
  misc:
    | AvatarModelProps
    | AvatarSkinToneProps
    | AvatarEyesProps
    | AvatarAnimationProps
  type: AvatarItemType
  deletedAt?: string | boolean | null
  createdAt?: string | null
}

export enum AvatarItemType {
  ANIMATIONS = 'animations',
  EYES = 'eyes',
  MODEL = 'model',
  SKIN_TONE = 'skinTone',
}

/** Valid mesh names for avatar body parts */
export enum AvatarBodyParts {
  ANKLES = 'Ankles',
  ARMS = 'Arms',
  CHEST_MIDDLE = 'ChestMiddle',
  CHEST_TOP = 'ChestTop',
  EYEBROWS = 'Brows',
  EYES = 'Eyes',
  FEET = 'Feet',
  HANDS = 'Hands',
  HEAD = 'Head',
  LEGS = 'Legs',
  PELVIS = 'Pelvis',
  SHOULDERS = 'Shoulders',
  STOMACH = 'Stomach',
  THIGHS = 'Thighs',
}

export interface ParentWearableProps {
  id?: string
  artistBrandId?: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
}

export interface WearableProps {
  id?: string
  allowedUserIds?: string[]
  allowedUsers?: Pick<UserPublic, 'id'>[]
  artistBrandId?: string
  artistBrand?: Partial<Omit<ArtistBrandProps, 'storeWearables'>>
  parentId?: string
  parent?: Partial<ParentWearableProps>
  modelId?: string
  model?: Partial<Omit<WorldAssetProps, 'artistBrand'>>
  normalMapId?: string
  normalMap?: Partial<Omit<WorldAssetProps, 'artistBrand'>>
  textureId?: string
  texture?: Partial<Omit<WorldAssetProps, 'artistBrand'>>
  displayName?: string
  category?: WearableCategory
  thumbnail?: string
  hiddenBodyParts?: AvatarBodyParts[]
  hiddenWearables?: WearableType[]
  overriddenWearables?: WearableCategory[]
  variantDisplayName?: string
  color?: string
  price?: number
  cap?: number
  categoryOrder?: number
  textureOrder?: number
  deletedAt?: string | boolean
  isPublic?: boolean
}

/** Categories for wearables UI */
export enum WearableCategory {
  BOTTOM = 'bottom',
  EARRINGS = 'earrings',
  HAIR = 'hair',
  HANDS = 'hands',
  HAT = 'hat',
  SHOES = 'shoes',
  TOP = 'top',
}

/** Valid mesh names for wearable assets */
export enum WearableType {
  BOTTOM = 'Bottom',
  BRACELET = 'Bracelet',
  EARRINGS = 'Earrings',
  GLOVES = 'Gloves',
  HAIR_BASE = 'HairBase',
  HAIR_TOP = 'HairTop',
  HANDS = 'Hands',
  HAT_HAIR = 'HatHair',
  HAT = 'Hat',
  RING = 'Ring',
  SHOES = 'Shoes',
  TOP = 'Top',
}
