import { useI18n } from '@momenthouse/I18n'
import { MomentLogo, NavLogo, Search as SearchIcon } from '@momenthouse/icons'
import { Button } from '@momenthouse/ui'
import cn from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'

import useNav from '../useNav'
import { UserMenu } from '../UserMenu'
import s from './Desktop.module.css'

/**
 * Component that renders the desktop navigation menu
 * @component
 */
export default function Desktop({ hasScrolled }) {
  const router = useRouter()

  const { asPath: pathname } = router
  const {
    isForCreatorsPage,
    loggedIn,
    openCreatorModal,
    openSearchModal,
    openSignInModal,
    openSignUpModal,
    isFetchingUser,
  } = useNav()
  const { t } = useI18n()

  return (
    <nav className={s.header}>
      <div className={s.first} />
      <div className={s.second}>
        <div className={s.secondLogo} onClick={() => router.push('/')}>
          <MomentLogo
            className={cn(s.navLogo, {
              [s.navLogoScroll]: hasScrolled,
            })}
          />
        </div>
      </div>
      <div
        className={cn(s.third, {
          [s.authMenu]: loggedIn,
        })}
      >
        {!isFetchingUser && (
          <div className={s.btnWrapper} id="btnWrapper">
            <Button
              aria-label="Open search"
              css={signInCSS}
              onClick={openSearchModal}
            >
              <SearchIcon />
            </Button>
          </div>
        )}
        {!isFetchingUser && !loggedIn && !isForCreatorsPage && (
          <>
            <div className={s.btnWrapper}>
              <Button
                css={signInCSS}
                data-id="navSignInButton"
                onClick={openSignInModal}
              >
                {t('signin')}
              </Button>
            </div>
            <div className={s.btnWrapper}>
              <Button
                css={signUpCSS}
                data-id="navSignUpButton"
                onClick={openSignUpModal}
              >
                {t('signup')}
              </Button>
            </div>
          </>
        )}
        {!isFetchingUser && isForCreatorsPage && !loggedIn && (
          <div className={cn(s.btnWrapper, s.forCreatorsBtn)}>
            <Button
              css={signUpCSS}
              data-id="navCreateMomentButton"
              onClick={openCreatorModal}
            >
              {t('artistApplTitle')}
            </Button>
          </div>
        )}
      </div>
      {!isFetchingUser && loggedIn && <UserMenu />}
    </nav>
  )
}

const signInCSS = {
  color: 'inherit',
  letterSpacing: 'normal',
  background: 'none',
  transition: '0.2s ease',
  '&:active, &:focus, &:hover': {
    background: 'none',
    boxShadow: 'none',
  },
}

const signUpCSS = {
  color: '#fff',
  letterSpacing: 'normal',
  backgroundColor: '#000',
  transition: '0.2s ease',
  '&:active, &:focus, &:hover': {
    backgroundColor: '#222',
    boxShadow: 'none',
  },
}
