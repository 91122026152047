import { CreatorProfileData } from 'api/static'
import React, { createContext, useContext, useMemo, useReducer } from 'react'

import { GlobalActionsProps, GlobalStateProps } from './types'

export const initialGlobalState: GlobalStateProps = {
  creatorProfile: null as CreatorProfileData,
  demoEmail: '',
  showAuthModal: false,
  showSearchModal: false,
  authModalView: 'WELCOME_VIEW',
  showCreatorApplicationModal: false,
  isOpen: false,
  setIsOpen: (val: boolean) => {},
  source: '',
  setSource: (val: string) => {},
  blockCloseForModal: false,
}

export const GlobalState = createContext<GlobalStateProps>(initialGlobalState)
GlobalState.displayName = 'GlobalState'

export const GlobalDispatch =
  createContext<React.Dispatch<GlobalActionsProps>>(null)
GlobalDispatch.displayName = 'GlobalDispatch'

export const useGlobalState = () => useContext(GlobalState)
export const useGlobalDispatch = () => useContext(GlobalDispatch)

export const globalReducer = (
  state: GlobalStateProps,
  action: GlobalActionsProps
) => {
  switch (action.type) {
    case 'SET_DEMO_EMAIL':
      return {
        ...state,
        demoEmail: action.payload,
      }

    case 'BLOCK_CLOSE_MODAL':
      return {
        ...state,
        blockCloseForModal: true,
      }

    case 'UNBLOCK_CLOSE_MODAL':
      return {
        ...state,
        blockCloseForModal: false,
      }

    case 'OPEN_SEARCH_MODAL':
      return {
        ...state,
        showSearchModal: true,
      }
    case 'CLOSE_SEARCH_MODAL':
      return {
        ...state,
        showSearchModal: false,
      }
    case 'OPEN_AUTH_MODAL':
      return {
        ...state,
        showAuthModal: true,
        showCreatorApplicationModal: false,
      }
    case 'CLOSE_AUTH_MODAL':
      return {
        ...state,
        showAuthModal: false,
        authModalView: 'WELCOME_VIEW',
      }
    case 'SET_AUTH_MODAL_VIEW':
      return {
        ...state,
        authModalView: action.payload,
        showAuthModal: true,
        showCreatorApplicationModal: false,
      }
    case 'OPEN_CREATOR_MODAL':
      return {
        ...state,
        showCreatorApplicationModal: true,
      }
    case 'CLOSE_CREATOR_MODAL':
      return {
        ...state,
        showCreatorApplicationModal: false,
      }

    default:
      throw new Error(`Can't get type.`)
  }
}

export const GlobalProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(globalReducer, initialGlobalState)
  const openAuthModal = () => dispatch({ type: 'OPEN_AUTH_MODAL' })
  const closeAuthModal = () => dispatch({ type: 'CLOSE_AUTH_MODAL' })
  const closeSearchModal = () => dispatch({ type: 'CLOSE_SEARCH_MODAL' })
  const openSearchModal = () => dispatch({ type: 'OPEN_SEARCH_MODAL' })

  const openSignInModal = () => {
    dispatch({ type: 'SET_AUTH_MODAL_VIEW', payload: 'SIGNIN_VIEW' })
  }
  const openFanSignUpModal = () =>
    dispatch({ type: 'SET_AUTH_MODAL_VIEW', payload: 'SIGNUP_FAN_VIEW' })
  const openCreatorSignUpModal = () =>
    dispatch({ type: 'SET_AUTH_MODAL_VIEW', payload: 'SIGNUP_CREATOR_VIEW' })
  const openSignUpModal = () =>
    dispatch({ type: 'SET_AUTH_MODAL_VIEW', payload: 'WELCOME_VIEW' })
  const setAuthModalView = (view: string) =>
    dispatch({ type: 'SET_AUTH_MODAL_VIEW', payload: view })
  const openCreatorModal = () => dispatch({ type: 'OPEN_CREATOR_MODAL' })
  const closeCreatorModal = () => dispatch({ type: 'CLOSE_CREATOR_MODAL' })

  const applyBlockCloseActionFromModal = () =>
    dispatch({ type: 'BLOCK_CLOSE_MODAL' })

  const unblockCloseActionFromModal = () => {
    dispatch({ type: 'UNBLOCK_CLOSE_MODAL' })
  }

  const value = useMemo(
    () => ({
      ...state,
      openAuthModal,
      closeAuthModal,
      setAuthModalView,
      closeSearchModal,
      openSearchModal,
      openSignInModal,
      openSignUpModal,
      openFanSignUpModal,
      openCreatorSignUpModal,
      openCreatorModal,
      closeCreatorModal,
      applyBlockCloseActionFromModal,
      unblockCloseActionFromModal,
    }),
    [state]
  )

  return (
    <GlobalState.Provider value={value}>
      <GlobalDispatch.Provider value={dispatch}>
        {children}
      </GlobalDispatch.Provider>
    </GlobalState.Provider>
  )
}

GlobalProvider.displayName = 'GlobalProvider'
