import { axiosAPI as client } from '@momenthouse/api'
import { getToken } from '@momenthouse/cookies'

client.interceptors.request.use((req) => {
  const hasSessionTimedOut = false

  const authToken = localStorage.getItem('MH_SHOPIFY_JWT') || getToken()

  if (
    authToken &&
    !hasSessionTimedOut &&
    (!req?.headers?.Authorization || req?.headers?.Authorization?.length < 7)
  ) {
    //@ts-ignore
    req.headers.Authorization = `Bearer ${authToken}`
  }

  return req
})

export default client
