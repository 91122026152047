import cn from 'classnames'
import throttle from 'lodash.throttle'
import * as React from 'react'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'
import s from './Navigation.module.css'
import useNav from './useNav'

/**
 * Component that renders the parent navigation menu.
 * Shows desktop or mobile depending on screen width.
 * @component
 */

export default function Navigation() {
  const [hasScrolled, setHasScrolled] = React.useState(false)
  const { isOpen } = useNav()

  /** Event listener for scroll event. Adds 'sticky' className to nav */
  React.useEffect(() => {
    const handleScroll = throttle(() => {
      const offset = 0
      const { scrollTop } = document.documentElement
      const scrolled = scrollTop > offset
      if (hasScrolled !== scrolled) {
        setHasScrolled(scrolled)
      }
    }, 200)
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [hasScrolled])

  return (
    <>
      <header
        className={cn(s.navRoot, {
          [s.sticky]: !isOpen && hasScrolled,
          [s.isOpen]: isOpen,
        })}
      >
        <Desktop hasScrolled={hasScrolled} />
        <Mobile />
      </header>
    </>
  )
}
