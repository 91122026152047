import { useAuth } from '@momenthouse/auth'
import { AWS_BUCKET_URL } from 'config'
import { useGlobalState } from 'context/global'
import { useRouter } from 'next/router'
import * as React from 'react'
/**
 * A utility hook for the navigation component
 * @component
 */
export default function useNav() {
  const { pathname } = useRouter()
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleDrawer = () => setIsOpen(!isOpen)
  const closeDrawer = () => setIsOpen(false)
  /* context */
  const { artistBrand, user, handleSignOut, isFetchingUser } = useAuth()

  const {
    openSignInModal,
    openSignUpModal,
    openCreatorModal,
    openSearchModal,
  } = useGlobalState()
  /* references */
  const isArtistBrand = Boolean(artistBrand)
  const loggedIn = Boolean(user)
  const artistBrandSlug = artistBrand?.slug
  const isForCreatorsPage = pathname === '/for-creators'
  const isHomepage = pathname === '/'

  /** Derive the user profile image **/
  const userImage = React.useMemo(() => {
    if (artistBrand?.image || user?.image) {
      return `${AWS_BUCKET_URL}${artistBrand?.image || user?.image}`
    }

    return ''
  }, [artistBrand?.image, user?.image])

  /** Derive the user name **/
  const userName = `${user?.firstName ? user.firstName : 'Profile'}${
    user?.lastName ? ` ${user?.lastName}` : ''
  }`

  return {
    artistBrand,
    artistBrandSlug,
    isArtistBrand,
    isForCreatorsPage,
    isHomepage,
    isOpen,
    toggleDrawer,
    closeDrawer,
    loggedIn,
    openCreatorModal,
    openSearchModal,
    openSignInModal,
    openSignUpModal,
    handleSignOut,
    user,
    userImage,
    isFetchingUser,
    userName,
  }
}
