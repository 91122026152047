import { getToken } from '@momenthouse/cookies'
import jwtDecode from 'jwt-decode'

const decodeJWTCustom = (customJwt?: string) => {
  let decodedJWT
  try {
    const localJWT = customJwt || getToken()
    // @ts-ignore
    decodedJWT = jwtDecode(localJWT)
  } catch (err) {
    return {
      hasValidJWT: false,
      artistBrandId: undefined,
      userId: undefined,
    }
  }

  return {
    artistBrandId: decodedJWT?.artistBrandId,
    email: decodedJWT?.email,
    isExpired: decodedJWT?.exp - Number(Date?.now() / 1000) < 0,
    paymentInfoId: decodedJWT?.paymentInfoId,
    userId: decodedJWT?.userId,
  }
}

export default decodeJWTCustom
