import { imageFullS3Path } from '@momenthouse/utils'
import getURL from 'libs/getURL'
import { NextSeoProps } from 'next-seo'

/**
 * Default SEO attributes applied to all pages
 *
 * See {@link https://github.com/garmeeh/next-seo}
 */

export const seoConfig: NextSeoProps = {
  title: 'Moment',
  description: 'Elevated Digital Experiences',
  openGraph: {
    title: 'Moment',
    description: 'Elevated Digital Experiences',
    type: 'website',
    locale: 'en_IE',
    url: 'https://www.moment.co',
    site_name: 'Moment',
    images: [
      {
        url: 'https://ik.imagekit.io/e10xeoyum/moment-banner.png',
        width: 1200,
        height: 628,
        alt: 'Moment',
      },
    ],
  },
  twitter: {
    handle: '@momenthouse',
    site: '@momenthouse',
    cardType: 'summary_large_image',
  },
  canonical: 'https://www.moment.co',
}

export const getCreatorSEO = (profileData): NextSeoProps => {
  const coverImage = imageFullS3Path(profileData.pageBackgroundImage)
  const title = `${profileData.displayName} | Moment`
  const image = `${getURL()}/api/og?bg=${encodeURIComponent(coverImage)}`

  return {
    title: title,
    description: 'Elevated Digital Experiences',
    openGraph: {
      title: title,
      description: 'Elevated Digital Experiences',
      type: 'website',
      locale: 'en_IE',
      url: `https://www.moment.co/${profileData.slug}`,
      site_name: 'Moment',
      images: [
        {
          url: image,
          width: 1200,
          height: 675,
          alt: title,
        },
      ],
    },
    twitter: {
      handle: '@momenthouse',
      site: '@momenthouse',
      cardType: 'summary_large_image',
    },
    canonical: `https://www.moment.co/${profileData.slug}`,
  }
}
