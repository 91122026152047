import { useI18n } from '@momenthouse/I18n'
import NavHamburger from '@momenthouse/icons/NavHamburger'
import NavLogo from '@momenthouse/icons/NavLogo'
import SearchIcon from '@momenthouse/icons/Search'
import { Button } from '@momenthouse/ui'
import cn from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'

import useNav from '../useNav'
import s from './Mobile.module.css'

/**
 * Component that renders the mobile navigation menu
 * @component
 */

export default function Mobile() {
  const router = useRouter()
  const { pathname: asPath } = router
  const { t } = useI18n()
  const {
    isArtistBrand,
    isOpen,
    toggleDrawer,
    closeDrawer,
    isForCreatorsPage,
    loggedIn,
    userImage,
    isFetchingUser,
    userName,
    openSignInModal,
    openSignUpModal,
    openCreatorModal,
    openSearchModal,
  } = useNav()

  return (
    <nav className={cn(s.mobileHeader)}>
      <div className={s.mobileMainContent}>
        <button
          className={cn(s.toggleBtn, { [s.toggleBtnOpen]: isOpen })}
          onClick={toggleDrawer}
        >
          <NavHamburger />
        </button>
        <div className={s.logoButton} onClick={() => router.push('/')}>
          <NavLogo />
        </div>
        {!isFetchingUser && (
          <div className={s.searchBtn}>
            <Button
              aria-label="Open search"
              css={searchButtonCSS}
              onClick={openSearchModal}
            >
              <SearchIcon />
            </Button>
          </div>
        )}
        {!isFetchingUser && !loggedIn && !isOpen && !isForCreatorsPage && (
          <div className={s.btnWrapper}>
            <Button
              css={mobileActionCSS}
              data-id="mobileNavSignInButton"
              onClick={openSignInModal}
            >
              {t('signin')}
            </Button>
          </div>
        )}
        {!isFetchingUser && isForCreatorsPage && !loggedIn && (
          <Button
            css={forCreatorsCSS}
            data-id="mobileNavCreateMomentButton"
            onClick={openCreatorModal}
          >
            {t('artistApplTitle')}
          </Button>
        )}
      </div>

      {isOpen && (
        <div className={cn(s.menuContent)}>
          <ul className={s.ul}>
            <>
              <li className={cn({ [s.noBorderItem]: !loggedIn })}>
                <Link
                  href="/faq"
                  onClick={closeDrawer}
                  {...(asPath === '/faq' && { className: s.activeItem })}
                >
                  {t('faq')}
                </Link>
              </li>
            </>
            {loggedIn && (
              <li
                className={s.profileItem}
                onClick={() => router.push(`/settings/profile`)}
              >
                <img
                  src={
                    userImage ||
                    'https://ik.imagekit.io/e10xeoyum/userImage.svg'
                  }
                  onLoad={(e) =>
                    ((e.target as HTMLSourceElement).style.opacity = '1')
                  }
                  height="30"
                  width="30"
                  alt="User avatar"
                />
                <p>{userName}</p>
              </li>
            )}
            {isArtistBrand && (
              <>
                <li>
                  <Link href="/dashboard" onClick={closeDrawer}>
                    {t('dashboard')}
                  </Link>
                </li>
                <li>
                  <Link
                    href="/for-creators"
                    onClick={closeDrawer}
                    {...(asPath === '/for-creators' && {
                      className: s.activeItem,
                    })}
                  >
                    {t('creatorHomePage')}
                  </Link>
                </li>
                <li className={s.noBorderItem}>
                  <Link
                    href="/settings/profile"
                    onClick={closeDrawer}
                    {...(asPath === '/settings/profile' && {
                      className: s.activeItem,
                    })}
                  >
                    {t('settings')}
                  </Link>
                </li>
              </>
            )}
            {!isArtistBrand && loggedIn && (
              <>
                <li>
                  <Link
                    href="/tickets"
                    onClick={closeDrawer}
                    {...(asPath === '/tickets' && {
                      className: s.activeItem,
                    })}
                  >
                    {t('myTickets')}
                  </Link>
                </li>
                <li>
                  <Link
                    href="/purchased-merch"
                    onClick={closeDrawer}
                    {...(asPath === '/purchased-merch' && {
                      className: s.activeItem,
                    })}
                  >
                    {t('purchasedMerch')}
                  </Link>
                </li>
                <li className={s.noBorderItem}>
                  <Link
                    href="/settings/profile"
                    onClick={closeDrawer}
                    {...(asPath === '/settings/profile' && {
                      className: s.activeItem,
                    })}
                  >
                    {t('settings')}
                  </Link>
                </li>
              </>
            )}
            {loggedIn ? (
              <Link href="/sign-out" className={s.menuItemButton}>
                {t('signout')}
              </Link>
            ) : (
              <>
                <li className={cn({ [s.noBorderItem]: !loggedIn })}>
                  <a
                    data-id="mobileNavMenuSignInButton"
                    onClick={() => {
                      closeDrawer()
                      openSignInModal()
                    }}
                  >
                    {t('signin')}
                  </a>
                </li>
                <li className={cn({ [s.noBorderItem]: !loggedIn })}>
                  <a
                    data-id="mobileNavMenuSignUpButton"
                    onClick={() => {
                      closeDrawer()
                      openSignUpModal()
                    }}
                  >
                    {t('signup')}
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </nav>
  )
}

const mobileActionCSS = {
  display: 'flex',
  maxWidth: '100%',
  padding: '0',
  border: 'none',
  borderRadius: '32px',
  color: '#fff',
  fontWeight: 600,
  fontSize: '14px',
  fontFamily: 'var(--fonts-sans)',
  letterSpacing: 'normal',
  textAlign: 'center',
  textDecoration: 'none',
  background: '#333332',
  outline: 'none',
  boxShadow:
    '0 1px 1px rgb(37 37 37 / 12%), 0 2px 5px rgb(37 37 37 / 12%), 0 0 1px rgb(37 37 37 / 12%)',
  cursor: 'pointer',
  transition: '0.2s ease',
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  appearance: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  width: '83px',
  height: '30px',
  margin: '0',
  marginLeft: 'auto',
  lineHeight: 1,
  whiteSpace: 'nowrap',
  backgroundColor: '#000',
  resize: 'none',
  '&:active, &:focus, &:hover': {
    backgroundColor: '#222',
    boxShadow: 'none',
  },
}

const searchButtonCSS = {
  display: 'inline-block',
  maxWidth: '100%',
  padding: '12px 0 12px 18px',
  border: 'none',
  borderRadius: 'var(--radii-sm)',
  color: '#fff',
  fontWeight: 700,
  fontSize: '15px',
  fontFamily: 'var(--fonts-sans)',
  letterSpacing: 'inherit',
  textAlign: 'center',
  textDecoration: 'none',
  background: 'none',
  outline: 'none',
  boxShadow: 'none',
  cursor: 'pointer',
  transition: 'background 0.2s ease, box-shadow 0.2s ease',
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  appearance: 'none',
  '&:active, &:focus, &:hover': {
    border: 'none',
    background: 'none',
    boxShadow: 'none',
  },
  // Search Icon
  svg: {
    top: '89px',
    left: '251px',
    display: 'flex',
    alignItems: 'center',
    width: '21px',
    height: '18px',
    margin: '5px',
    color: '#000',
    fontWeight: 457,
    fontSize: '18px',
    fontStyle: 'normal',
    lineHeight: '100%',
    textAlign: 'center',
  },
}

const forCreatorsCSS = {
  display: 'flex',
  maxWidth: '100%',
  padding: '0',
  border: 'none',
  borderRadius: '32px',
  color: '#fff',
  fontWeight: 600,
  fontSize: '14px',
  fontFamily: 'var(--fonts-sans)',
  letterSpacing: 'normal',
  textAlign: 'center',
  textDecoration: 'none',
  background: '#333332',
  outline: '0',
  boxShadow:
    ' 0 1px 1px rgb(37 37 37 / 12%), 0 2px 5px rgb(37 37 37 / 12%), 0 0 1px rgb(37 37 37 / 12%)',
  cursor: 'pointer',
  transition: '0.2s ease',
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  appearance: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  width: '132px',
  height: '30px',
  margin: '0',
  marginLeft: 'auto',
  lineHeight: 1,
  whiteSpace: 'nowrap',
  backgroundColor: '#000',
  resize: 'none',
  '&:active, &:focus, &:hover': {
    backgroundColor: '#222',
    boxShadow: 'none',
  },
}
