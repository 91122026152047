import { useI18n } from '@momenthouse/I18n'
import Avatar from '@momenthouse/icons/Avatar'
import cn from 'classnames'
import Link from 'next/link'
import * as React from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'

import useNav from '../useNav'
import s from './UserMenu.module.css'

/**
 * Component that renders the desktop user dropdown
 * @component
 */
export default function UserMenu(props) {
  const { t } = useI18n()
  const { userImage, artistBrandSlug, isArtistBrand } = useNav()
  const ref = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLDivElement>(null)
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)

  /** Event handler for click event. Toggles dropdown visibility. */
  const handleClickOutside = (event) => {
    if (triggerRef.current && triggerRef.current.contains(event.target)) {
      setDropdownVisible(!dropdownVisible)
    } else if (ref.current && !ref.current.contains(event.target)) {
      setDropdownVisible(false)
    }
  }

  /** Adds event handler for click event */
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return (
    <div
      className={s.details}
      role="menu"
      title={t('userMenu')}
      ref={triggerRef}
      onMouseEnter={() => setDropdownVisible(true)}
      onMouseLeave={() => setDropdownVisible(false)}
    >
      <summary className={s.summary}>
        {!userImage ? (
          <Avatar className={s.userFallback} />
        ) : (
          <img
            src={userImage}
            className={s.userImg}
            alt={t('userMenu')}
            onLoad={(e) =>
              ((e.target as HTMLSourceElement).style.opacity = '1')
            }
            height="20"
            width="20"
          />
        )}
      </summary>
      <div
        className={cn(s.detailsMenu, { [s.open]: dropdownVisible })}
        ref={ref}
      >
        {isArtistBrand ? (
          <>
            <Link href={`/dashboard`}>{t('dashboard')}</Link>
            <Link href={`/${artistBrandSlug}`}>{t('creatorHomePage')}</Link>
            <Link href="/settings/profile">{t('settings')}</Link>
            <Link href="/sign-out" tabIndex={0}>
              {t('signout')}
            </Link>
          </>
        ) : (
          <>
            <Link href="/tickets">{t('myTickets')}</Link>
            <Link href="/purchased-merch">{t('purchasedMerch')}</Link>
            <Link href="/settings/profile">{t('settings')}</Link>
            <Link href="/sign-out" tabIndex={0}>
              {t('signout')}
            </Link>
          </>
        )}
      </div>
    </div>
  )
}
