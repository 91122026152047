import 'moment/locale/de.js'
import 'moment/locale/es.js'
import 'moment/locale/fr.js'
import 'moment/locale/he.js'
import 'moment/locale/is.js'
import 'moment/locale/ja.js'
import 'moment/locale/nl.js'
import 'moment/locale/zh-cn.js'

import { AuthProvider } from '@momenthouse/auth'
import GeoProvider from '@momenthouse/geo'
import { I18nProvider } from '@momenthouse/I18n'
import { useTheme } from '@momenthouse/ui'
import Navigation from 'components/Common/Navigation'
import CookieBanner from 'components/CookieBanner'
import { PAYPAL_CLIENT_ID, STRIPE_PUBLIC_KEY } from 'config'
import { GTM_MOMENTHOUSE } from 'constants/analytics'
import { seoConfig } from 'constants/seo'
import { GlobalProvider } from 'context/global'
import useFocus from 'hooks/useFocus'
import useIdentify from 'hooks/useIdentify'
import usePrivateRoute from 'hooks/usePrivateRoute'
import usePublicOnlyRoute from 'hooks/usePublicOnlyRoute'
import moment from 'moment'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { DefaultSeo, NextSeoProps } from 'next-seo'
import { ReactNode } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useHotkeys } from 'react-hotkeys-hook'

const ABSwitcher = dynamic(() => import('components/ArtistBrandSwitcher'), {
  ssr: false,
})
const GlobalModal = dynamic(() => import('components/GlobalModal/modal'), {
  ssr: false,
})

type Props = {
  children: ReactNode
  /* The document.title value of the page */
  title?: string
  /* Render the default navigation component */
  showNav?: boolean
  /* Render the custom navigation component for artist + checkout */
  showCreatorNav?: boolean
  /* Only accessible to signed-in users */
  isPrivate?: boolean
  /* Only accessible to signed-out users */
  isPublicOnly?: boolean
  /* Disables the default SEO <meta /> tags */
  customSEO?: NextSeoProps
}

export default function GlobalLayout({
  children,
  showNav,
  title,
  isPrivate,
  isPublicOnly,
  customSEO,
}: Props) {
  /* Internationalization - query value sent from _middleware.ts */
  const { query } = useRouter()
  const locale = query.locale || 'en-US'
  // Sets locale for date/time functionality
  moment.locale(locale as string)

  /* Toggle document 'light' | 'dark' className */
  const { applyTheme } = useTheme()
  useHotkeys('cmd+/,ctrl+/', (e) => {
    e.preventDefault()
    const isDarkMode = document.documentElement.className.includes('dark')
    applyTheme(isDarkMode ? 'light' : 'dark')
  })

  const seo = customSEO
    ? customSEO
    : {
        ...seoConfig,
        ...(title && { title: `${title} | Moment` }),
      }

  useFocus()
  useIdentify()

  return (
    <>
      <DefaultSeo {...seo} />
      <GeoProvider>
        <I18nProvider locale={locale}>
          <AuthProvider>
            <GlobalProvider>
              {/* @ts-ignore Type error: Type '{ children: Element; }' has no properties in common with type 'IntrinsicAttributes'. */}
              <div id="root">
                {/* @ts-ignore React 18 */}
                <DndProvider backend={HTML5Backend}>
                  {showNav && <Navigation />}
                  <InnerGlobalLayout
                    isPrivate={isPrivate}
                    isPublicOnly={isPublicOnly}
                  >
                    {children}
                  </InnerGlobalLayout>
                  <GlobalModal />
                  <ABSwitcher />
                  <CookieBanner />
                </DndProvider>
              </div>
            </GlobalProvider>
          </AuthProvider>
        </I18nProvider>
      </GeoProvider>
      <Script
        id="onetrust-cookie-consent"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script={
          process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
            ? '566338d3-ba55-4a21-b4c9-c461c828e960'
            : '566338d3-ba55-4a21-b4c9-c461c828e960-test'
        }
        strategy="beforeInteractive"
        onLoad={() => {
          function OptanonWrapper() {}
          // @ts-ignore
          window?.OneTrust?.Close?.()
        }}
        onError={(e) => {
          console.error('Script failed to load', e)
        }}
      />
      <Script
        id="google-places-init"
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY}&libraries=places`}
        strategy="afterInteractive"
      />
    </>
  )
}

const InnerGlobalLayout = ({ children, isPrivate, isPublicOnly }) => {
  usePublicOnlyRoute(isPublicOnly)
  usePrivateRoute(isPrivate)

  return <>{children}</>
}
