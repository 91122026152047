import { getToken } from '@momenthouse/cookies'
import axios from 'axios'

import endpoints from './endpoints'

export const loadToken = (): string => {
  return getToken() || ''
}

export const getRequestHeader = async (
  token?: string
): Promise<{ headers: any }> => {
  let authToken = localStorage.getItem('MH_SHOPIFY_JWT') || token

  if (!authToken) {
    const jwt = loadToken()
    authToken = jwt
  }

  const authBearer = { Authorization: `Bearer ${authToken}` }
  return { headers: authBearer }
}

export const makeRequest = async (
  method: string,
  url: string,
  data: any = null,
  headers: any = null
) => {
  let requestHeaders = headers

  if (!requestHeaders) {
    const newHeaders = await getRequestHeader()
    if (newHeaders) {
      requestHeaders = newHeaders.headers
    }
  }

  // @ts-ignore
  return axios({
    method,
    url,
    data,
    headers: requestHeaders,
    withCredentials: true,
  })
}
