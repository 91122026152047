import {
  MembershipUpdatePaymentMethodApiConfig,
  MembershipUpdatePaymentMethodApiRes,
} from '@momenthouse/types'

import { t } from './endpointType'

const membershipEndpoints = {
  updatePaymentMethod: {
    method: 'post',
    url: 'api/v1/payment/membership/link/payment_method_update',
    config: t<
      MembershipUpdatePaymentMethodApiConfig,
      MembershipUpdatePaymentMethodApiRes
    >(),
  },
}

export default membershipEndpoints
