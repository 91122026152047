import { useGeo } from '@momenthouse/geo'
import { Button } from '@momenthouse/ui'
import { GDPR_COUNTRIES } from 'components/UserInfo/constants'
import Cookies from 'js-cookie'
import { includes } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

import {
  BottomBanner,
  buttonCSS,
  Content,
  Cookie,
} from './CookieBanner.stitches'

const COOKIE_NAME = 'cookieChecked'

const CookieBanner: FC = () => {
  const { countryCode } = useGeo()
  const [acceptedCookies, setAcceptedCookies] = useState(true)

  const acceptCookies = () => {
    setAcceptedCookies(true)
    Cookies.set(COOKIE_NAME, 'accepted', {
      expires: 365,
      secure: process.env.VERCEL === '1',
    })
    try {
      // @ts-ignore
      window?.OneTrust?.AllowAll?.()
    } catch (e) {
      console.error('CookieBanner: ', e)
    }
  }

  /**
   * Gets location data and sets cookie required
   */
  const checkCookieRequired = async () => {
    try {
      if (Cookies.get(COOKIE_NAME)) {
        // Don't need to have a network call if cookie is already checked anyways
        return
      }
      const isGdpr = includes(GDPR_COUNTRIES, countryCode)
      if (isGdpr) {
        // If we're in europe, set the cookies as required
        setAcceptedCookies(false)
      } else if (isMobile) {
        // Rest of world mobile does not need cookie banner
        setAcceptedCookies(true)
      } else {
        // Rest of world desktop does need cookie banner
        setAcceptedCookies(false)
      }
    } catch (err) {
      console.error('Error getting host country.')
    }
  }

  useEffect(() => {
    checkCookieRequired()
  }, [])

  return acceptedCookies ? null : (
    <Cookie>
      <BottomBanner>
        <Content>
          This site uses cookies to improve your experience. By clicking, you
          agree to our
          <a href="/privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          .
        </Content>
        <Button css={buttonCSS} onClick={acceptCookies}>
          Accept Cookies
        </Button>
      </BottomBanner>
    </Cookie>
  )
}

export default CookieBanner
