import Cookies, { CookieAttributes } from 'js-cookie'

class Cookie {
  config: CookieAttributes
  constructor(config: CookieAttributes) {
    this.config = config
  }

  public get(key: string) {
    return Cookies.get(key)
  }

  public set(key: string, value: string | object) {
    const cookie = Cookies.get(key)

    if (cookie) {
      this.delete(key)
    }

    return Cookies.set(key, value, this.config)
  }

  public delete(key: string) {
    const cookie = Cookies.get(key)
    if (cookie) {
      Cookies.remove(key, this.config)
    }
  }
}

export default Cookie
