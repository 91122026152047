export const CRUD_API_HOST = process.env.NEXT_PUBLIC_CRUD_API_HOST
export const IS_WORLD = process.env.NEXT_PUBLIC_IS_WORLD

if (!CRUD_API_HOST) {
  throw new Error(
    `The environment variable NEXT_PUBLIC_CRUD_API_HOST is missing`
  )
}

export default { CRUD_API_HOST, IS_WORLD }
