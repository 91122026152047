import { WorldProps } from './worlds'

export enum MembershipTierType {
  'subscription' = 'subscription',
  'progression' = 'progression',
}

export enum SubscriptionStatus {
  'active' = 'active',
  'pending_cancel' = 'pending_cancel',
  'canceled' = 'canceled',
}

export enum SubscriptionCadenceType {
  'monthly' = 'monthly',
  'annually' = 'annually',
}

export type ProgressionTier = {
  id?: string
  worldId?: string
  name?: string
  description?: string
  misc?: any
  pointsThreshold?: number

  createdAt?: string | null
  updatedAt?: string | null
  deletedAt?: string | null

  type: MembershipTierType
  icon?: string

  artistBrandId?: string // artist brand id or slug
  monthlyPriceId?: string // stripe
  monthlyPrice?: number
  annualPrice?: number
  annualPriceId?: string // stripe
  productId?: string // stripe

  world?: Partial<WorldProps>
}

export type UserSubscription = {
  id: string

  userId?: string
  tierId: string
  worldId?: string
  createdAt: string
  updatedAt: string
  subscriptionId: string
  startDate?: string
  status?: SubscriptionStatus
  cadence?: SubscriptionCadenceType

  tierInfo?: Partial<ProgressionTier>
  payment?: any
}
