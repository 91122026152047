export const GDPR_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GB',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
]

export const SALES_TAX_NAMING_OVERRIDE = {
  AU: 'GST',
  MY: 'DST',
  IN: 'GST',
  NZ: 'GST',
  SG: 'GST',
  JP: 'JCT',
  AT: 'VAT',
  BH: 'VAT',
  BY: 'VAT',
  BE: 'VAT',
  BG: 'VAT',
  CL: 'VAT',
  CO: 'VAT',
  HR: 'VAT',
  CY: 'VAT',
  CZ: 'VAT',
  DK: 'VAT',
  EE: 'VAT',
  FI: 'VAT',
  FR: 'VAT',
  GE: 'VAT',
  DE: 'VAT',
  GR: 'VAT',
  HU: 'VAT',
  IS: 'VAT',
  IE: 'VAT',
  IT: 'VAT',
  LV: 'VAT',
  LT: 'VAT',
  LU: 'VAT',
  MT: 'VAT',
  MX: 'VAT',
  MD: 'VAT',
  NL: 'VAT',
  NG: 'VAT',
  NO: 'VAT',
  PL: 'VAT',
  PT: 'VAT',
  RO: 'VAT',
  RU: 'VAT',
  SA: 'VAT',
  RS: 'VAT',
  SK: 'VAT',
  SI: 'VAT',
  ZA: 'VAT',
  KR: 'VAT',
  ES: 'VAT',
  SE: 'VAT',
  CH: 'VAT',
  TW: 'VAT',
  TH: 'VAT',
  TR: 'VAT',
  AE: 'VAT',
  GB: 'VAT',
  UZ: 'VAT',
}

export const SALES_TAX_INCLUDED_IN_SERVICE_FEE_COUNTRIES = [
  'AU',
  'AT',
  'BH',
  'BE',
  'BY',
  'BG',
  'CO',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IS',
  'IN',
  'IE',
  'IT',
  'JP',
  'LV',
  'LT',
  'LU',
  'MT',
  'MY',
  'NL',
  'NZ',
  'NG',
  'NO',
  'PL',
  'PT',
  'RO',
  'RS',
  'SA',
  'SK',
  'SG',
  'SI',
  'ZA',
  'KR',
  'ES',
  'SE',
  'CH',
  'TW',
  'TR',
  'AE',
  'GB',
  'UZ',
]

export const VALID_EMAIL_DOMAINS = [
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'hotmail.com',
  'msn.com',
]
