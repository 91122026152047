import { GetSocialWallApiConfig, GetSocialWallApiRes } from '@momenthouse/types'

import { t } from './endpointType'

const socialEndpoints = {
  getSocialWall: {
    method: 'get',
    url: 'api/v1/social/:artistBrandId/wall',
    config: t<GetSocialWallApiConfig, GetSocialWallApiRes>(),
  },
} as const

export default socialEndpoints
