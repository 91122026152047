export enum MessageReviewStatus {
  /** Message is awaiting CS review */
  'pending' = 'pending',
  /** User has been banned for this message */
  'user_banned' = 'user_banned',
  /** User has been banned across all worlds for this message */
  'user_banned_global' = 'user_banned_global',
  /** Message has been deemed inappropriate and deleted */
  'deleted' = 'deleted',
  /** Message has been deemed ok and sent */
  'approved' = 'approved',
}

export enum MessageSource {
  /** Detected by automod */
  'Neutrino' = 'Neutrino',
  /** Detected by LLM */
  'LLM' = 'LLM',
  /** Manually reported by a user */
  'Report' = 'Report',
}

export enum ThreadMessageReportTypes {
  /** It's spam */
  'spam' = 'spam',
  /** Hate speech */
  'hateSpeech' = 'hateSpeech',
  /** Down vote */
  'downvote' = 'downvote',
  /** I just don’t like it */
  'dontLike' = 'dontLike',
  /** Bullying or harassment */
  'bullyOrHarass' = 'bullyOrHarass',
  /** False information */
  'falseInfo' = 'falseInfo',
  /** Violent or dangerous */
  'violentOrDanger' = 'violentOrDanger',
  /** Scam or fraud */
  'scamOrFraud' = 'scamOrFraud',
  /** Board auto report */
  'boardAutoReport' = 'boardAutoReport',
}

export type ReasonTotals = {
  [key in ThreadMessageReportTypes]: number
}

export type Message = {
  /** The PubNub channel id */
  channel_id?: string
  /** Timestamp */
  created_at: string
  /** The censored version of the sent message */
  message_cleaned: string
  /** The uncensored version of the sent message */
  message_raw: string
  /** The title if it's a board post */
  message_title?: string
  /** The ranking of how egregious the message is, the # of repeated offenses, etc. */
  priority: number
  /** The user id of the employee who decides the status */
  reviewer_id: string
  /** The source of the report (neutrino, user report, etc) */
  source: MessageSource.Neutrino | MessageSource.Report
  /** The review status of the message */
  status:
    | MessageReviewStatus.approved
    | MessageReviewStatus.user_banned
    | MessageReviewStatus.user_banned_global
    | MessageReviewStatus.pending
    | MessageReviewStatus.deleted
  /** The PubNub timetoken of the message */
  message_id: string
  /** The user id of the message author */
  user_id: string
  /** The username of the message author at the time of sending */
  username: string
  /** The domain of the world */
  world_domain: string
  /** The id of the world */
  world_id: string
  /** Misc audit log that shows previous actions */
  audit_log: any[]
  /** The selected reason when a user reports a message */
  report_reason?: ThreadMessageReportTypes
  /** Contains the # of times each ThreadMessageReportType has been reported */
  reason_totals?: ReasonTotals
  /* The PubNub action timetoken (used for message deletion) */
  actionTimetoken?: string
}
