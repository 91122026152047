import CRUD_API_HOST from '@momenthouse/api/src/env'
import { User, UserProps, UserPublic } from '@momenthouse/types'
import axios from 'axios'
import { forEach, trim } from 'lodash'

/**
 * returns the full name of a user
 * @param user user object
 * @param defaultName fallback name
 * @returns string name
 */
export const getFullName = (
  user?: User | UserPublic | UserProps,
  defaultName = 'Fan'
): string => {
  const firstName = user?.firstName
  const lastName = user?.lastName

  if (!firstName && !lastName) {
    return defaultName
  }

  return trim(`${firstName || ''} ${lastName || ''}`)
}

/**
 * Helper function to check if a user should be able to see certain extra super user features across our website
 *
 * 'super_read_only' - this user can view everything, but might not have access to modify everything (this is enforced at the API level)
 * @param {Object} user
 * @returns {boolean}
 */
export const superUserChecker = (user?: any) => {
  return (
    user?.isSuper ||
    user?.access === 'super' ||
    user?.access === 'super_read_only'
  )
}

/**
 * get instagram handle
 * @param instagram
 * @returns
 */
export const getInstagramHandle = (instagram?: string): string => {
  if (!instagram) return ''

  let handle = instagram

  if (handle.includes('http') || handle.includes('https')) {
    handle = handle.split('//')[1]
  }

  if (handle.includes('instagram.com')) {
    handle = handle.substr(handle.indexOf('/') + 1)
  }

  if (handle[handle.length - 1] === '/') {
    handle = handle.slice(0, -1)
  }

  if (handle[0] === '@') {
    handle = handle.substring(1)
  }

  return handle
}
