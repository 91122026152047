import { SystemPreferences } from './api'
import { ArtistBrandProps } from './artist'
import { AvatarProps } from './avatar'
import { UserSubscription } from './membership'

export interface JwtDecoded {
  artistBrandId?: string
  exp?: number
  iat?: number
  userId?: string
}

export type ShippingAddress = {
  city: string
  country: string
  userId: string
  fullName: string
  line1: string
  countryAlpha2Code?: string
  createdAt?: Date
  deletedAt?: Date | string | null
  id: string
  line2?: string | null
  province?: string
  rawAddress?: string
  type?: string
  updatedAt?: Date | null
  zip?: string | number | null
}

export type UserWorldStatus = {
  datetime: string
  userId: string
  worldId: string
}

export enum UserBanType {
  MUTED = 'MUTED',
  BANNED = 'BANNED',
}

export type UserProps = {
  access?: string
  applemusic: string
  artistBrand?: ArtistBrandProps
  artistBrandId: string
  bio?: string
  cashApp: string
  createdAt: Date
  email: string
  externalBillingId: string
  firstName: string
  globalBanned?: boolean
  banUntil?: string
  banType?: UserBanType
  id: string
  image?: string
  instagram?: string
  isSuper?: boolean
  isVerified: boolean
  lang: string
  lastName: string
  merch: string
  originalArtistBrandId: string
  passwordHash: string
  paymentMethods?: any[]
  permissions?: string[]
  paypal: any | null
  phone: any | null
  resetPasswordToken: string
  shippingAddresses?: ShippingAddress[]
  worldStatus?: UserWorldStatus
  slug: string
  soundcloud: string
  spotify: string
  stripeAccountId?: string
  stripeCustomerId: null
  timezone: string
  updatedAt?: Date
  userId: string
  username?: string
  venmo: string
  token?: string
  avatar?: AvatarProps
  avatarImage?: string
  hasCustomAvatar?: boolean
  verifiedCreator?: boolean
  worldsOnboarding?: boolean
  onboarding?: { [key: string]: boolean }
  systemPreferences?: SystemPreferences
  expoPushToken?: string | null
  subscriptions?: Partial<UserSubscription>[]
  patreonUserId?: string
}
