import { useAuth } from '@momenthouse/auth'
import { getToken } from '@momenthouse/cookies'
import axios from 'axios'
import { CRUD_API_HOST } from 'config'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

/**
 * Asynchronously checks the user JWT token
 * Redirects home if invalid
 *
 * @param {boolean} isPrivate
 */
export const usePrivateRoute = (isPrivate?: boolean) => {
  const router = useRouter()
  const userToken = getToken()
  const { handleSignOut } = useAuth()

  const verifyJWT = async (token) => {
    try {
      const url = `${CRUD_API_HOST}/api/v1/auth/jwt/verify`
      const headers = { headers: { Authorization: `Bearer ${token}` } }
      const { data } = await axios(url, headers)
      return data
    } catch (err) {
      console.error(err)
      return { isValid: false }
    }
  }

  const checkJWT = async (token) => {
    try {
      // TODO: Use outdated to log the user out
      const { isValid, outdated } = await verifyJWT(token)

      if (outdated) {
        handleSignOut(() => {
          router.push('/')
        })
      } else if (!isValid) {
        router.push('/')
      }
    } catch (e) {
      console.error('usePrivateRoute: ', e)
    }
  }

  useEffect(() => {
    if (isPrivate && !userToken) {
      router.push('/')
    } else if (isPrivate && userToken) {
      checkJWT(userToken)
    }
  }, [isPrivate, userToken])
}

export default usePrivateRoute
