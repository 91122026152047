import { decodeJWTCustom, useAuth } from '@momenthouse/auth'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

/**
 * Checks for the presence of a JWT token
 * Redirects to /dashboard or /tickets if found
 *
 * @param {boolean} isPublic
 */
export const usePublicOnlyRoute = (isPublic?: boolean) => {
  const router = useRouter()
  const { user, artistBrandId } = useAuth()
  const userToken = user?.token

  useEffect(() => {
    const { hasValidJWT } = decodeJWTCustom(userToken)
    if (isPublic && userToken) {
      router.push(artistBrandId ? '/dashboard' : '/tickets')
    }
  }, [isPublic, userToken])
}

export default usePublicOnlyRoute
