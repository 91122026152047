import * as React from 'react'

export default function NavHamburger() {
  return (
    <svg viewBox="0 0 22 22">
      <path
        d="M 0 6 C 0 5.448 0.448 5 1 5 L 21 5 C 21.552 5 22 5.448 22 6 L 22 6 C 22 6.552 21.552 7 21 7 L 1 7 C 0.448 7 0 6.552 0 6 Z"
        fill="currentColor"
      ></path>
      <path
        d="M 0 16 C 0 15.448 0.448 15 1 15 L 21 15 C 21.552 15 22 15.448 22 16 L 22 16 C 22 16.552 21.552 17 21 17 L 1 17 C 0.448 17 0 16.552 0 16 Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}
