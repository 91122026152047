import { GLOBAL_FOCUSED_WINDOW_KEY } from '@momenthouse/utils'
import { useEffect } from 'react'

export default function useFocus() {
  /** on current window focus */
  const onWindowFocus = () => {
    /** delete global window property `focusedWindow`
     * that means the current focused window is the main window
     */
    delete window[GLOBAL_FOCUSED_WINDOW_KEY]
  }
  /** Effect to set global window property `focusedWindow` as the current window.
   * used to determine what window should the portal will be attached to
   * see Portal and PortalWindow component in components folder
   */
  useEffect(() => {
    typeof window !== 'undefined' &&
      window.addEventListener('focus', onWindowFocus)
    return () =>
      typeof window !== 'undefined' &&
      window.removeEventListener('focus', onWindowFocus)
  }, [])
}
