type Props = { className?: string }

export default function Avatar({ className }: Props) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 18 17"
    >
      <path
        fill="currentColor"
        d="M9 16.617c4.531 0 8.25-3.726 8.25-8.258C17.25 3.836 13.523.11 9 .11 4.469.11.75 3.836.75 8.36c0 4.532 3.727 8.258 8.25 8.258zm0-7.07c-1.57-.008-2.781-1.336-2.781-3.04C6.21 4.899 7.445 3.524 9 3.524c1.555 0 2.781 1.375 2.781 2.985 0 1.703-1.21 3.054-2.781 3.039zm0 5.492c-1.672 0-3.61-.695-4.758-1.96.969-1.376 2.672-2.142 4.758-2.142 2.063 0 3.773.75 4.758 2.141C12.609 14.344 10.672 15.04 9 15.04z"
      ></path>
    </svg>
  )
}
