import { AWS_BUCKET_URL } from '@momenthouse/constants'
import { head, last, replace, startsWith } from 'lodash'

export const MOMENT_IMAGES_ASSET_ORIGIN = {
  S3: 'https://moment-house-images.s3.us-west-1.amazonaws.com/',
  STATIC_MH_CDN: 'https://static.mhcdn.tv/',
  IMAGEKIT: 'https://ik.imagekit.io/e10xeoyum/',
}
export const WORLD_ASSET_ORIGIN = {
  S3: 'https://moment-house-worlds.s3.us-west-1.amazonaws.com',
  CLOUDFRONT: 'https://world-assets.momenthouse.com',
  IMAGEKIT: 'https://ik.imagekit.io/momenthouse/worlds',
}
/**
 * return image URL with S3 bucket url
 * @param image image string
 * @returns full image url
 */
export const imageFullS3Path = (image: string): string => {
  //for avatar images: all avatar images are made using urlbox
  if (startsWith(image, 'https://')) {
    return image
  }

  if (head(image || '') === '/' && last(AWS_BUCKET_URL) !== '/') {
    image = `${image}`.substring(1)
  }

  return image ? `${AWS_BUCKET_URL}${image}` : ''
}

/**
 * Takes all inputs of images and attempts to get them in IMAGEKIT format
 * @param imageUrl
 */
export const sanitizeImageUrl = (imageUrl: string, quality = 80): string => {
  let outputImageUrl = imageUrl

  outputImageUrl = replace(
    outputImageUrl,
    WORLD_ASSET_ORIGIN.S3,
    WORLD_ASSET_ORIGIN.IMAGEKIT
  )

  outputImageUrl = replace(
    outputImageUrl,
    WORLD_ASSET_ORIGIN.CLOUDFRONT,
    WORLD_ASSET_ORIGIN.IMAGEKIT
  )

  outputImageUrl = replace(
    outputImageUrl,
    MOMENT_IMAGES_ASSET_ORIGIN.S3,
    MOMENT_IMAGES_ASSET_ORIGIN.IMAGEKIT
  )

  outputImageUrl = replace(
    outputImageUrl,
    MOMENT_IMAGES_ASSET_ORIGIN.STATIC_MH_CDN,
    MOMENT_IMAGES_ASSET_ORIGIN.IMAGEKIT
  )

  outputImageUrl = `${outputImageUrl}?tr=q-${quality}`
  return outputImageUrl
}

/**
 * return s3 bucket image URL with fallback image
 * @param image image string
 * @returns full image url
 */
export const getFullImageUrl = (image?: string): string => {
  if (!image) {
    return imageFullS3Path('static/media/moment-square.png')
  }
  if (image?.includes('https://')) {
    return image
  }

  return imageFullS3Path(image)
}

/**
 * Passed into the 'next/image' component to utilize
 * imagekit transformations
 *
 * See {@link https://nextjs.org/docs/api-reference/next/image#loader}
 */
export const loader = ({ src, width, quality = 80 }) => {
  return `${src}?tr=w-${width},q-${quality}`
}
