import { getRequestHeader } from '@momenthouse/api/src/helpers'

import { CRUD_API_HOST, IS_WORLD } from './env'
import client from './libs/axios'
import { ISignInWithEmail, ISignUp } from './types'

export const signInWithEmailandPassword = async (payload: ISignInWithEmail) => {
  return await client.post(
    `${CRUD_API_HOST}/api/v1/auth/login`,
    JSON.stringify(payload)
  )
}

export const signUp = async (options: ISignUp) => {
  return await client.post(
    `${CRUD_API_HOST}/api/v1/users/${options.isArtist ? '?isArtist=true' : ''}`,
    JSON.stringify(options)
  )
}

export const getArtistBrandData = async (slugOrId: string) => {
  return await client.get(
    `${CRUD_API_HOST}/api/v1/artist_brands/${slugOrId}/private`
  )
}

export const getUserProfile = async (userId: string) => {
  return await client.get(
    `${CRUD_API_HOST}/api/v1/users/${userId}${
      !!IS_WORLD ? '?fetchAvatar=true' : ''
    }`
  )
}

export const attemptResignJwtToken = async () => {
  try {
    const headers = await getRequestHeader()
    const ret = await client.post(
      `${CRUD_API_HOST}/api/v1/auth/jwt/resign`,
      {},
      {
        headers,
      }
    )
    return ret.data
  } catch (ex) {}
}
