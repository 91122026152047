import { MsgType } from '../momentPageType'
import { ApiConfig } from './helperTypes'
import { FollowerType } from './social'

export enum NOTIFICATION_USERS_TO_NOTIFY {
  ACTIVE = 'active',
}

// Mainly used for what image to show in the notification (came from user or artist)
export enum NOTIFICATION_SENT_FROM_TYPE {
  ARTIST = 'artist',
  USER = 'user',
}

// NOTIFICATIONS

export interface INotificationMetadataBase {
  sentFromId?: string // ArtistBrand or User ID
  notificationId?: string

  worldDomain?: string // Determines if is world specific
}

export enum BoardPostType {
  POST = 'post',
  COMMENT = 'comment',
  REPLY = 'reply',
}

export interface IWorldNotification {
  worldMetadata: {
    displayName: string
    id: string
    domain: string
  }
}

// World Specific Notifications
export interface IScheduledAppearanceNotificationMetadata
  extends INotificationMetadataBase,
    IWorldNotification {
  eventId: string
  scheduledAppearanceDate: string
  reminderHoursBefore: number
}
export interface IBoardUpvoteNotification
  extends INotificationMetadataBase,
    IWorldNotification {
  postId: string // ID of Post or Comment that has been upvoted
  postTitle: string // Title of Post that had been upvoted
  postType: BoardPostType // Type of Post that had been upvoted
}
export interface IBoardCommentNotification
  extends INotificationMetadataBase,
    IWorldNotification {
  topLevelPostId: string // Top level Post ID
  parentPostId: string // ID of Post or Comment that has been commented on
  commentId: string // ID of Comment
  commentText: string // Text of Comment
  postType: BoardPostType // Type of Post that had been upvoted
}
export interface ICreatorBoardPostNotification
  extends INotificationMetadataBase,
    IWorldNotification {
  topLevelPostId: string // Top level Post ID
  postTitle: string // Text of Post
}
export interface IWorldCustomNotification
  extends INotificationMetadataBase,
    IWorldNotification {
  messageBody: string
  messageTitle: string
}

// World Agnostic Notifications
export interface IPartyNotificationMetadata extends INotificationMetadataBase {
  partyId: string
  partyName: string
  accessCode: string
}

export interface IFollowUserNotification extends INotificationMetadataBase {
  fromSlug: string
  fromId: string
  isFollowingBack: boolean
}
export interface ICustomNotification extends INotificationMetadataBase {
  messageBody: string
  messageTitle: string
}

export type INotificationMetadata = Partial<IPartyNotificationMetadata> &
  Partial<IScheduledAppearanceNotificationMetadata> &
  Partial<IBoardUpvoteNotification> &
  Partial<IBoardCommentNotification> &
  Partial<IFollowUserNotification> &
  Partial<ICustomNotification> &
  Partial<ICreatorBoardPostNotification>

/**
 * Each MessageType has a specific type of
 */
export enum NOTIFICATION_MESSAGE_TYPE {
  // Follower
  NOTIFICATION_RECIEVED_FOLLOWER = 'NOTIFICATION_RECIEVED_FOLLOWER', // Spelled Wrong lol, but don't change because its in the DB

  // Broadcast or Appearance. Ideally these are part of our event notification system
  NOTIFICATION_BROADCAST_LIVE = 'NOTIFICATION_BROADCAST_LIVE', // When an artist broadcast goes live
  NOTIFICATION_BROADCAST_NOT_LIVE = 'NOTIFICATION_BROADCAST_NOT_LIVE', // When an artist broadcast goes offline
  NOTIFICATION_SCHEDULED_APPEARANCE = 'NOTIFICATION_SCHEDULED_APPEARANCE', // When there is a scheduled appearance at a certain time
  NOTIFICATION_SCHEDULED_APPEARANCE_NOTIFIED = 'NOTIFICATION_SCHEDULED_APPEARANCE_NOTIFIED', // When there is a scheduled appearance at a certain time
  NOTIFICATION_APPEARANCE_REMINDER = 'NOTIFICATION_APPEARANCE_REMINDER', // Reminder for a scheduled appearance

  // Board Creator Interaction
  CREATOR_POST = 'CREATOR_POST', // When a creator posts (only posts)
  CREATOR_POST_PIN = 'CREATOR_POST_PIN', // Creator pins a post (only posts)
  CREATOR_POST_COMMENT = 'CREATOR_POST_COMMENT', // When a creator comments (Includes posts, comments, replies)
  CREATOR_POST_UPVOTE = 'CREATOR_POST_UPVOTE', // When a creator upvotes (Includes posts, comments, replies)

  // Board Interaction
  BOARD_POST = 'BOARD_POST', // When a regular user posts (only posts)
  BOARD_POST_COMMENT = 'BOARD_POST_COMMENT', // Another user comments on a post (Includes posts, comments, replies)
  BOARD_POST_UPVOTE = 'BOARD_POST_UPVOTE', // Another user upvotes a post (only posts)
  BOARD_COMMENT_UPVOTE = 'BOARD_COMMENT_UPVOTE', // Another user upvotes a post (comments, replies only)

  // Chats
  CREATOR_CHATS_ANNOUNCEMENT = 'CREATOR_CHATS_ANNOUNCEMENT', // When a creator sends a chat in the announcement channel

  // Direct Messaging
  DIRECT_MESSAGE = 'DIRECT_MESSAGE', // User sends a direct message
  CREATOR_DIRECT_MESSAGE = 'CREATOR_DIRECT_MESSAGE', // Not to be Implemented yet - When a creator sends a direct message to a user
  GROUP_DIRECT_MESSAGE = 'GROUP_DIRECT_MESSAGE', // Not to be Implemented yet - When a group sends direct message to a user

  // Custom Notifications
  NOTIFICATION_CUSTOM = 'NOTIFICATION_CUSTOM', // Moment Owned Custom Notification, can send notifications that go to all users
  NOTIFICATION_WORLD_CUSTOM = 'NOTIFICATION_WORLD_CUSTOM', // World Owned Custom Notification, can send notifications that go to all users in a world

  AGGREGATED_BOARD_POST_UPVOTE = 'AGGREGATED_BOARD_POST_UPVOTE',
  AGGREGATED_BOARD_POST_COMMENT = 'AGGREGATED_BOARD_POST_COMMENT',
  AGGREGATED_BOARD_COMMENT_UPVOTE = 'AGGREGATED_BOARD_COMMENT_UPVOTE',
}

type NotificationAudience = 'artist' | 'user' | 'event'

type NotificationDeviceTargets =
  | 'mobile'
  | 'all'
  | 'mobile_desktop'
  | 'email'
  | 'mobile_email'
  | 'desktop_email'

export type RawNotification = {
  targetDevices: NotificationDeviceTargets
  audience: NotificationAudience
  triggerDate: Date | null
  notificationSenderText: string
  title: string
  body: string
  sentFrom: string
  id: string
  status: string
  sentFromType: FollowerType
  createdAt: Date
  updatedAt: Date | null
  deletedAt: Date | null
}

// Make sure NotificationPayload is CONSISTENT WITH BACKEND
export type NotificationPayload = {
  notificationId: string
  notificationSenderText: string
  notifierId: string
  notifierSlug: string
  userId: string
  createdAt: string
  image: string
  verifiedCreator?: boolean
  // TODO: JUST TO BE USED FOR QA WILL BE NUKE BY PIETRO SOON
  times: number
  timePeriod: string
  msgType: NOTIFICATION_MESSAGE_TYPE
  liveNotification: INotificationMetadata
  rawNotification: RawNotification
  receiverCountWeb?: number
  receiverCountMobile?: number
  completionTimeSeconds?: number
  pushCompletionTimeSeconds?: number
  /** if logged in user is following the notif sender */
  isFollowingBack: boolean
  hidePartyJoin: boolean
  /** sender avatar image */
  avatarImage?: string // TOOD: Deprecate - should be using the image field
}

//* *************************************************************************/

export type GetNotificationsData = undefined
export type GetNotificationsParams = undefined
export type GetNotificationsPaths = undefined
export type GetNotificationsRes = { notifications: NotificationPayload[] }

export type GetNotificationsApiConfig = ApiConfig<
  GetNotificationsData,
  GetNotificationsParams,
  GetNotificationsPaths
>

export type GetNotificationsApiRes = GetNotificationsRes

//* *************************************************************************/
