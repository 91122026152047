import { useAuth } from '@momenthouse/auth'
import { useEffect } from 'react'

/**
 * Fires an identify() call to our 3rd
 * party analytics libraries
 */
export const useIdentify = () => {
  const { user } = useAuth()
  useEffect(() => {}, [user?.id])
}

export default useIdentify
