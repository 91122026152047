export const AGORA_APP_ID =
  process.env.NEXT_PUBLIC_AGORA_APP_ID || process.env.REACT_APP_AGORA_APP_ID
export const AGORA_APP_ID_MG =
  process.env.NEXT_PUBLIC_AGORA_APP_ID_MG ||
  process.env.REACT_APP_AGORA_APP_ID_MG
export const AGORA_LOG =
  process.env.NEXT_PUBLIC_AGORA_LOG || process.env.REACT_APP_AGORA_LOG
export const AGORA_REST_KEY = process.env.NEXT_PUBLIC_AGORA_REST_KEY
export const AGORA_REST_SECRET = process.env.NEXT_PUBLIC_AGORA_REST_SECRET
export const AGORA_STORAGE_KEY = process.env.NEXT_PUBLIC_AGORA_STORAGE_KEY
export const AGORA_STORAGE_SECRET = process.env.NEXT_PUBLIC_AGORA_STORAGE_SECRET
export const AIRTABLE_API_KEY =
  process.env.NEXT_PUBLIC_AIRTABLE_API_KEY ||
  process.env.REACT_APP_AIRTABLE_API_KEY
export const AIRTABLE_BASE_ID =
  process.env.NEXT_PUBLIC_AIRTABLE_BASE_ID ||
  process.env.REACT_APP_AIRTABLE_BASE_ID
export const AWS_BUCKET_URL =
  process.env.NEXT_PUBLIC_AWS_BUCKET_URL || process.env.REACT_APP_AWS_BUCKET_URL
export const CRUD_API_HOST =
  process.env.NEXT_PUBLIC_CRUD_API_HOST || process.env.REACT_APP_CRUD_API_HOST
export const ENVIRONMENT =
  process.env.NEXT_PUBLIC_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT
export const MUX_DATA_KEY =
  process.env.NEXT_PUBLIC_MUX_DATA_KEY || process.env.REACT_APP_MUX_DATA_KEY
export const PAYPAL_CLIENT_ID =
  process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID ||
  process.env.REACT_APP_PAYPAL_CLIENT_ID
export const PLACES_API =
  process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY ||
  process.env.REACT_APP_GOOGLE_PLACES_API_KEY
export const SHOPIFY_CLIENT_ID =
  process.env.NEXT_PUBLIC_SHOPIFY_CLIENT_ID ||
  process.env.REACT_APP_SHOPIFY_CLIENT_ID
export const STRIPE_PUBLIC_KEY =
  process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY ||
  process.env.REACT_APP_STRIPE_PUBLIC_KEY
export const UI_HOST =
  process.env.NEXT_PUBLIC_UI_HOST || process.env.REACT_APP_UI_HOST
