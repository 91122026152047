import { Moment } from 'moment'

import {
  ArtistBrandProps,
  BagItem,
  BundleProps,
  CheckoutDigitalGoods,
  CheckoutPhysicalGoods,
  EncodedAvatarConfig,
  EventConfig,
  EventProps,
  GeofenceProps,
  GuestProps,
  PhysicalGoodOptions,
  PurchasedPhyicalGoods,
  SongProps,
  TicketMasterStore,
  TourProps,
  UserBanType,
  UserProps,
  WorldBaseLighting,
  WorldItemProps,
  WorldMusicAsset,
  WorldShopifyStore,
} from '../index'
import {
  ArtistBrand,
  AverageSalePrice,
  Demo,
  Event,
  MerchSale,
  MuteType,
  Stream,
  Ticket,
  TicketSalePerDay,
  TopCountry,
  User,
  UserPublic,
} from './baseTypes'
import { ApiConfig } from './helperTypes'

// This is where all the type information about the frontend is located
// EXAMPLE:
// export type EndpointReqData = undefined
// export type EndpointReqParams = undefined
// export type EndpointReqPaths = undefined
// export type EndpointReqRes = undefined

// export type EndpointApiConfig = ApiConfig<
//   EndpointReqData,
//   EndpointReqParams,
//   EndpointReqPaths
// >
// export type EndpointApiRes = ApiResponse<EndpointReqRes>

export type EventAndSuccessApiRes = { event?: EventProps; success?: string[] }

export type EventsAndSuccessApiRes = {
  events?: EventProps[]
  success?: string[]
}

//* *************************************************************************/
export type LoginReqData = {
  email: string
  password: string
  captchaToken?: string
}
export type LoginReqRes = { token: string; user: Partial<UserProps> }

export type LoginApiConfig = ApiConfig<LoginReqData, undefined, undefined>
export type LoginApiRes = LoginReqRes

//* *************************************************************************/
export type ChangePasswordReqData = {
  userId: string
  password: string
  confirmPassword: string
  oldPassword: string
  captchaToken: string
}

export type ChangePasswordApiRes = { message?: string }
export type ChangePasswordApiConfig = ApiConfig<
  ChangePasswordReqData,
  undefined,
  undefined
>

//* *************************************************************************/
export type ForgotPasswordApiConfig = ApiConfig<
  { email?: string; refUrl?: string; captchaToken: string },
  undefined,
  undefined
>

// eslint-disable-next-line
export type ForgotPasswordApiRes = {} // no outputs

//* *************************************************************************/
// eslint-disable-next-line
export type LogoutReqRes = {} // no outputs

export type LogoutApiConfig = ApiConfig
export type LogoutApiRes = LogoutReqRes

//* *************************************************************************/
export type ResignApiConfig = ApiConfig
export type ResignApiRes = LoginApiRes

//* *************************************************************************/
export type VerifyDailyPasswordReqPaths = { password: string }

export type VerifyDailyPasssswordApiConfig = ApiConfig<
  undefined,
  undefined,
  VerifyDailyPasswordReqPaths
>
export type VerifyDailyPassssworApiRes = {
  isValid?: boolean
  error?: string
}

//* *************************************************************************/
export type CreateAccessCode = {
  accessCodeGroupId?: string
  eventId?: string
  customId?: string
  discountValue?: number
  markIfUsed?: boolean
  excludeMerch?: boolean
  quantity?: number
}

type AccessCode = {
  accessCodeCount?: string
  accessCodeGroupId?: string
  accessCodeUsed?: string
  discountValue?: number
  excludeMerch?: boolean
  isPatreon?: boolean
  markIfUsed?: boolean
}

export type PatreonAccount = {
  email: string
  id: string
  memberships: any[]
}

export type PatreonCampaign = {
  id: string
  name: string
  tiers: { id: string; name: string }[]
}

export type CreateAccessCodeReqBody = CreateAccessCode
export type CreateAccessCodeReqRes = AccessCode

export type CreateAccessCodeApiConfig = ApiConfig<
  CreateAccessCodeReqBody,
  undefined,
  undefined
>

export type CreateAccessCodeApiRes = ApiConfig<CreateAccessCodeReqRes>

export type RequireAccessCodeCheck = { eventId?: string }

export type RequireAccessCodeCheckApiConfig = ApiConfig<
  { needsAccessCode: boolean },
  undefined,
  RequireAccessCodeCheck
>

export type RequireAccessCodeCheckApiRes = EventAndSuccessApiRes

//* *************************************************************************/
export type UpdateAccessCodeReqBody = Partial<CreateAccessCode>
export type UpdateAccessCodeReqRes = AccessCode

export type UpdateAccessCodeApiConfig = ApiConfig<
  UpdateAccessCodeReqBody,
  { eventId?: string },
  undefined
>

export type UpdateAccessCodeApiRes = ApiConfig<UpdateAccessCodeReqRes>

//* *************************************************************************/
export type GetCSVCodeApiConfig = ApiConfig<
  undefined,
  { eventId?: string },
  { accessCodeGroupId?: string }
>

export type DeleteAccessCodeApiConfig = ApiConfig<
  undefined,
  { eventId?: string },
  { accessCodeGroupId?: string }
>

//* *************************************************************************/
export type CountCodeApiConfig = ApiConfig<
  undefined,
  { artistBrandId?: string },
  undefined
>

export type CountCodeReqRes = {
  hasCodes?: boolean
}

export type CountCodeApiRes = ApiConfig<CountCodeReqRes>

//* *************************************************************************/
export type AccessCodeDropDateApiConfig = ApiConfig<
  { dropDate?: string },
  { eventId?: string },
  undefined
>

//* *************************************************************************/
export type CreateGeofenceReqBody = Partial<GeofenceProps>
export type CreateGeofenceReqRes = {
  geofences: GeofenceProps[]
  success: string[]
}

export type CreateGeofenceApiConfig = ApiConfig<
  CreateGeofenceReqBody,
  undefined,
  { eventId?: string }
>

export type UpdateGeofenceApiConfig = ApiConfig<
  CreateGeofenceReqBody,
  undefined,
  { eventId?: string; geofenceId?: string }
>

export type DeleteGeofenceApiConfig = ApiConfig<
  undefined,
  undefined,
  { eventId?: string; geofenceId?: string }
>

export type CreateGeofenceApiRes = ApiConfig<CreateGeofenceReqRes>
export type DeleteGeofenceApiRes = CreateGeofenceReqRes

//* *************************************************************************/
export type Question = {
  email?: string
  event?: Partial<EventProps>
  eventId?: string
  id?: string
  question: string
}

export type GetQuestionsReqPaths = { eventId: string }
export type GetQuestionsReqRes = { questions: Question[] }

export type GetQuestionsApiConfig = ApiConfig<
  undefined,
  undefined,
  GetQuestionsReqPaths
>
export type GetQuestionsApiRes = GetQuestionsReqRes

//* *************************************************************************/
export type ToggleQuestionsReqPaths = { eventId: string }

export type ToggleQuestionsApiConfig = ApiConfig<
  { allowQuestions?: boolean },
  undefined,
  ToggleQuestionsReqPaths
>
export type ToggleQuestionsApiRes = EventAndSuccessApiRes

//* *************************************************************************/
export type UploadISponsorImageApiConfig = ApiConfig<
  FormData,
  undefined,
  { eventId: string }
>
export type UploadSponsorImageApiRes = { event: EventProps }

export type RemoveSponsorImageReqBody = {
  sponsorImage?: string
  deleteSponsor?: boolean
}
export type RemoveSponsorImageApiConfig = ApiConfig<
  RemoveSponsorImageReqBody,
  undefined,
  { eventId: string }
>

//* *************************************************************************/
export type Tier = {
  name?: string
  increase?: number
  increaseType?: string
  datetime?: Moment
  applyToBundles?: boolean
  isBase?: boolean
  price?: string
}

export type AddTierReqBody = Tier
export type AddTierReqPaths = { eventId: string }
export type AddTierReqRes = EventAndSuccessApiRes & { error?: string }

export type AddTierApiConfig = ApiConfig<
  AddTierReqBody,
  undefined,
  AddTierReqPaths
>
export type AddTierApiRes = AddTierReqRes

export type DeleteTierReqPaths = { eventId: string; dateTime: string }
export type DeleteTierApiConfig = ApiConfig<
  undefined,
  undefined,
  DeleteTierReqPaths
>

//* *************************************************************************/
export type SetBundleOrderReqBody = { orderedBundleIds: string[] }
export type SetBundleOrderReqRes = EventAndSuccessApiRes

export type SetBundleOrderApiConfig = ApiConfig<
  SetBundleOrderReqBody,
  undefined
>
export type SetBundleOrderApiRes = ApiConfig<SetBundleOrderReqRes>

//* *************************************************************************/
export type UpdateEventReqBody = EventProps & EventConfig
export type UpdateEventReqPaths = { eventIdOrSlug: string }

export type UpdateEventApiConfig = ApiConfig<
  UpdateEventReqBody,
  undefined,
  UpdateEventReqPaths
>
export type UpdateEventApiRes = EventAndSuccessApiRes

//* *************************************************************************/
export type CreateAfterPartyEventReqBody = EventProps & EventConfig
export type CreateAfterPartyReqPaths = { eventId: string }

export type CreateAfterPartyApiConfig = ApiConfig<
  CreateAfterPartyEventReqBody,
  undefined,
  CreateAfterPartyReqPaths
>

export type DeleteAfterPartyApiConfig = ApiConfig<
  undefined,
  undefined,
  CreateAfterPartyReqPaths
>

export type DeleteAfterPartyApiRes = EventAndSuccessApiRes

//* *************************************************************************/
export type GetPrivateEventsReqPaths = { artistBrandId: string }

export type GetPrivateEventsApiConfig = ApiConfig<
  undefined,
  undefined,
  GetPrivateEventsReqPaths
>

export type GetPrivateEventsApiRes = EventProps[]

//* *************************************************************************/
export type GetToursReqPaths = { artistBrandId: string }
export type GetToursApiConfig = ApiConfig<
  undefined,
  undefined,
  GetToursReqPaths
>
export type GetToursApiRes = TourProps[]

//* *************************************************************************/
export type TestRedisEmitterReqPaths = undefined
export type TestRedisEmitterReqBody = { redisEmitterMessage: string }
export type TestRedisEmitterApiConfig = ApiConfig<
  TestRedisEmitterReqBody,
  undefined,
  undefined
>
export type TestRedisEmitterApiRes = any

//* *************************************************************************/
export type RefundAfterPartyReqBody = {
  artistMessage?: string
}
export type RefundAfterPartyReqPaths = { eventId: string }

export type RefundAfterPartyApiConfig = ApiConfig<
  RefundAfterPartyReqBody,
  undefined,
  RefundAfterPartyReqPaths
>
export type RefundAfterPartyApiRes = EventAndSuccessApiRes

//* *************************************************************************/
export type GuestPost = {
  email?: string
  eventId?: string
  ticketType?: string
  sendEmail?: boolean
  timezone?: string
}

export type AddNewGuestReqBody = GuestPost
export type ResendNewGuestTicketReqBody = {
  id?: string
  eventId?: string
  artistBrandSlug?: string
}

export type AddNewGuestApiConfig = ApiConfig<
  AddNewGuestReqBody,
  undefined,
  undefined
>
export type ResendNewGuestTicketApiConfig = ApiConfig<
  ResendNewGuestTicketReqBody,
  undefined,
  undefined
>
export type DeleteGuestApiConfig = ApiConfig<
  undefined,
  undefined,
  { ticketId?: string }
>
export type AddNewGuestApiRes = GuestProps

//* *************************************************************************/
export type RescheduleEventReqBody = {
  artistMessage?: string
  datetime?: string
}
export type RescheduleEventReqPaths = { eventId: string }

export type RescheduleEventApiConfig = ApiConfig<
  RescheduleEventReqBody,
  undefined,
  RescheduleEventReqPaths
>
export type RescheduleEventApiRes = EventAndSuccessApiRes & { error?: string }

//* *************************************************************************/
export type DeleteBundleReqBody = { bundleId: string }
export type DeleteBundleReqPaths = { eventIdOrSlug: string }

export type DeleteBundleApiConfig = ApiConfig<
  DeleteBundleReqBody,
  undefined,
  DeleteBundleReqPaths
>
export type DeleteBundleApiRes = EventAndSuccessApiRes

//* *************************************************************************/
/*
  NEW BUNDLE DELETE
  TODO: Remove old bundle delete when new bundle flow is released
*/

export type DeleteBundleNewReqPaths = { bundleId: string }

export type DeleteBundleNewApiConfig = ApiConfig<
  undefined,
  undefined,
  DeleteBundleNewReqPaths
>
export type DeleteBundleNewApiRes = EventAndSuccessApiRes

//* *************************************************************************/
export type AddSongReqBody = { eventId: string; songTitle: string }

export type AddSongApiConfig = ApiConfig<AddSongReqBody, undefined, undefined>
export type AddSongApiRes = SongProps

//* *************************************************************************/
export type DeleteSongReqPath = { eventId: string; songId: string }

export type DeleteSongApiConfig = ApiConfig<
  undefined,
  undefined,
  DeleteSongReqPath
>
export type DeleteSongApiRes = Record<string, never>

//* *************************************************************************/
export type UpdateBundlePrintFul = {
  id?: string
  name?: string
  description?: string
  price?: string | number
  limitedInventory?: boolean
  bundleCap?: number
  physicalGoods?: CheckoutPhysicalGoods[]
  digitalGoods?: CheckoutDigitalGoods[]
  merchType?: string
  shippingRates?: string
  geofenceRestrictions?: string
}

export type UpdateBundleBody = {
  id?: string
  name?: string
  description?: string
  price?: string | number
  limitedInventory?: boolean
  bundleCap?: number
  physicalGoods?: CheckoutPhysicalGoods[]
  digitalGoods?: CheckoutDigitalGoods[]
  merchType?: string
  shippingRates?: string
  geofenceRestrictions?: string
}

export type UpdateBundleReqBody = FormData
export type UpdateBundleReqPaths = { eventIdOrSlug?: string; bundleId?: string }

export type UpdateBundleApiConfig = ApiConfig<
  UpdateBundleReqBody,
  undefined,
  UpdateBundleReqPaths
>

export type UpdateBundlePrintFulApiConfig = ApiConfig<
  UpdateBundlePrintFul,
  undefined,
  UpdateBundleReqPaths
>
export type UpdateBundleApiRes = EventAndSuccessApiRes

//* *************************************************************************/
export type CreateBundlesReqBody = FormData
export type CreateBundlesApiConfig = ApiConfig<
  CreateBundlesReqBody,
  undefined,
  undefined
>
export type CreateBundlesApiRes = EventsAndSuccessApiRes

//* *************************************************************************/
export type SaveAddOnReqBody = {
  id?: string
  name?: string
  price?: number
  bundleCap?: number
  description?: string
  merchType?: string
  addOnInfo?: string
}
export type SaveAddOnReqPaths = { eventIdOrSlug: string }
export type SaveAddOnReqRes = EventAndSuccessApiRes

export type SaveAddOnApiConfig = ApiConfig<
  SaveAddOnReqBody,
  undefined,
  SaveAddOnReqPaths
>
export type SaveAddOnApiRes = ApiConfig<SaveAddOnReqRes>

//* *************************************************************************/
export type ExportExternalAddOnPurchaseReqPaths = {
  eventIdOrSlug: string
  artistBrandId: string
}
export type ExportExternalAddOnPurchaseReqRes = {
  event?: EventProps
  success?: string[]
}

export type ExportExternalAddOnPurchaseApiConfig = ApiConfig<
  undefined,
  undefined,
  ExportExternalAddOnPurchaseReqPaths
>
export type ExportExternalAddOnPurchaseApiRes = string

//* *************************************************************************/
export type DeleteAddOnReqBody = { bundleId: string }
export type DeleteAddOnReqPaths = { eventIdOrSlug: string }
export type DeleteAddOnReqRes = EventAndSuccessApiRes

export type DeleteAddOnApiConfig = ApiConfig<
  DeleteAddOnReqBody,
  undefined,
  DeleteAddOnReqPaths
>
export type DeleteAddOnApiRes = DeleteAddOnReqRes

//* *************************************************************************/
export type DeleteEventImageReqBody = { deleteImage: boolean }
export type DeleteEventImageReqPaths = { eventIdOrSlug: string }
export type DeleteEventImageReqRes = EventAndSuccessApiRes

export type DeleteEventImageApiConfig = ApiConfig<
  DeleteEventImageReqBody,
  undefined,
  DeleteEventImageReqPaths
>
export type DeleteEventImageApiRes = DeleteEventImageReqRes

//* *************************************************************************/
export type UploadImageReqBody = FormData
export type UploadEventImageReqPaths = { eventIdOrSlug: string }
export type UploadUserImageReqPaths = { userId: string }
export type UploadArtistBrandImageReqPaths = { artistBrandId: string }
export type UploadImageReqRes = { imageUrl?: string }
export type UploadUserOrArtistImageReqRes = { newFile?: string }

export type UploadEventImageApiConfig = ApiConfig<
  UploadImageReqBody,
  undefined,
  UploadEventImageReqPaths
>
export type UploadUserImageApiConfig = ApiConfig<
  UploadImageReqBody,
  undefined,
  UploadUserImageReqPaths
>
export type UploadArtistBrandImageApiConfig = ApiConfig<
  UploadImageReqBody,
  undefined,
  UploadArtistBrandImageReqPaths
>
export type UploadImageApiRes = UploadImageReqRes
export type UploadUserOrArtistImageApiRes = UploadUserOrArtistImageReqRes

//* *************************************************************************/
export type UpdateUserReqBody = Omit<Partial<UserProps>, 'avatar'> & {
  avatar?: EncodedAvatarConfig
}
export type UpdateUserReqPaths = { userId: string }
export type UpdateUserReqRes = { error?: string | string[]; success?: string[] }

export type UpdateUserApiConfig = ApiConfig<
  UpdateUserReqBody,
  undefined,
  UpdateUserReqPaths
>
export type UpdateUserApiRes = UpdateUserReqRes

//* *************************************************************************/
export type DeleteOwnAccountApiConfig = ApiConfig<
  Record<string, never>,
  undefined,
  undefined
>
export type DeleteOwnAccountApiRes = Record<string, never>

//* *************************************************************************/
// TODO: add strict types for remaining 'any' types
export type ConfirmPayIntentData = {
  paymentIntentId: string
  paymentMethodId: string
  method: any
  eventIds: string[]
  price: number
  priceDisplay: number
  location: any
  quantity: number
  selectedCurrency: string
  timezone: string
  mailingList: boolean
  selectedBundle: BundleProps
  saveCard: boolean
  bundleDetails: any
  accessCode: string
  userInfo: any
  shoppingBag?: BagItem[]
  addOns: BundleProps[]
  addOnId?: string
  returnPrice?: boolean
  shippingAddressId?: string
  checkoutSource?: string
  giftingInfo?: any
  artistBrandId?: string
}
export type ConfirmPayIntentRes = {
  convertedSubtotal: number
  discountAmount: number
  priceUsd: number
  salesTax: number
  serviceFee: number
  shippingRate: number
  totalPrice: number
  usdPrice: number
  error?: string
  id?: string
  confirmUrl?: string
  intentId?: string
}

export type ConfirmPayIntentApiConfig = ApiConfig<Partial<ConfirmPayIntentData>>
export type ConfirmPayIntentApiRes = ConfirmPayIntentRes

//* *************************************************************************/
export type ConfirmClivePayIntentApiConfig = ApiConfig<ConfirmPayIntentData>
export type ConfirmClivePayIntentApiRes = ConfirmPayIntentRes

export type ConfirmMerchGalleryPayIntentApiConfig =
  ApiConfig<ConfirmPayIntentData>
export type ConfirmMerchGalleryPayIntentApiRes = ConfirmPayIntentRes

export type ConfirmAddOnPayIntentApiConfig = ApiConfig<ConfirmPayIntentData>
export type ConfirmAddOnPayIntentApiRes = ConfirmPayIntentRes

export type ConfirmTippingPayIntentApiConfig = ApiConfig<ConfirmPayIntentData>
export type ConfirmTippingPayIntentApiRes = ConfirmPayIntentRes

//* *************************************************************************/
export type ImportedGoods = {
  id?: string
  name?: string
  images?: { src: string; height: number; width: number }[]
  options?: PhysicalGoodOptions[]
  description?: string
}

/**
 * @typedef {object} Merch
 * @prop {string} id
 * @prop {string} bundleId
 * @prop {string} productId
 * @prop {string} type
 * @prop {ImportedGoods} importedGood
 * @prop {string} provider
 * @prop {string} artistBrandId
 * @prop {null | Date} createdAt
 * @prop {null | Date} deletedAt
 * @prop {boolean=} shippingAddressRequired
 * @prop {any=} geofenceRestrictions
 * @prop {number=} cap
 * @prop {{ [key: string]: Variant }=} variantCaps
 */

export type GetMerchsPaths = { artistBrandId: string }
export type GetMerchsRes = { merchResult: CheckoutPhysicalGoods[] }

export type GetMerchsApiConfig = ApiConfig<undefined, undefined, GetMerchsPaths>
export type GetMerchsApiRes = GetMerchsRes

//* *************************************************************************/
export type AddMerchsRes = { id: string; message: string }
export type AddMerchsReqBody = FormData

export type AddMerchsApiConfig = ApiConfig<
  AddMerchsReqBody,
  undefined,
  undefined
>
export type AddMerchsApiRes = AddMerchsRes

//* *************************************************************************/
export type UpdateMerchPaths = { merchId: string }
export type UpdateMerchsRes = { id: string; message: string }
export type UpdateMerchsReqBody = FormData

export type UpdateMerchsApiConfig = ApiConfig<
  UpdateMerchsReqBody,
  undefined,
  UpdateMerchPaths
>
export type UpdateMerchsApiRes = UpdateMerchsRes

//* *************************************************************************/
export type DeleteMerchPaths = { merchId: string }
export type DeleteMerchParams = { shopifyOnly?: boolean }
export type DeleteMerchRes = {
  message: string
  merchResult?: CheckoutPhysicalGoods[]
}

export type DeleteMerchApiConfig = ApiConfig<
  undefined,
  DeleteMerchParams,
  DeleteMerchPaths
>
export type DeleteMerchApiRes = DeleteMerchRes

//* *************************************************************************/
export type UpdatePayMentMethodPaths = { paymentMethodID: string }

export type UpdatePayMentMethodApiConfig = ApiConfig<
  undefined,
  undefined,
  UpdatePayMentMethodPaths
>
export type UpdatePayMentMethodApiRes = {
  id: string
  user: Partial<UserProps>
  error: any
}

//* *************************************************************************/
export type DeletePayMentMethodPaths = { paymentMethodID: string }

export type DeletePayMentMethodApiConfig = ApiConfig<
  undefined,
  undefined,
  DeletePayMentMethodPaths
>
export type DeletePayMentMethodApiRes = {
  id: string
  user: Partial<UserProps>
  error: any
}

//* *************************************************************************/
export type AddShippingAddressBody = {
  location: {
    address: {
      fullName: string
      address1: string
      line2?: string
      city: string
      province_code: string
      country_code: string
      zip?: string
      raw: string
      manual: boolean
      phone?: string
    }
  }
  noLogin: null | boolean
}

export type AddDigitalAddressBody = {
  fullName: string
  raw: string
}

export type AddShippingAddressRes = {
  city: string
  country: string
  createdAt: Date
  deletedAt?: null | string
  fullName: string
  id: string
  line1: string
  line2?: null | string
  province: string
  rawAddress: string
  updatedAt: Date
  userId: string
  zip: null | string
  error?: string
}

export type AddDigitalAddressRes = {
  fullName: string
  raw: string
}

export type AddShippingAddressApiConfig = ApiConfig<AddShippingAddressBody>
export type AddDigitalAddressApiConfig = ApiConfig<AddDigitalAddressBody>

export type AddShippingAddressApiRes = AddShippingAddressRes

//* *************************************************************************/
export type DeleteShippingAddressPaths = { shippingAddressId: string }

export type DeleteShippingAddressApiConfig = ApiConfig<
  undefined,
  undefined,
  DeleteShippingAddressPaths
>
export type DeleteShippingAddressApiRes = any

//* *************************************************************************/
export type GetEventPaths = { eventIdOrSlug: string }
export type GetEventRes = {
  event: Event
  stream: Stream
}

export type GetEventApiConfig = ApiConfig<undefined, undefined, GetEventPaths>
export type GetEventApiRes = GetEventRes

//* *************************************************************************/
export type RegisterDeviceData = {
  eventId: string
  ticketId: string
}
export type RegisterDeviceRes = { token: string; ticket: Ticket; error: string }

export type RegisterDeviceApiConfig = ApiConfig<RegisterDeviceData>
export type RegisterDeviceApiRes = RegisterDeviceRes

//* *************************************************************************/
export type GetCookiesPaths = {
  streamId: string
}
export type GetCookiesRes = {
  eventState: Event
  rateLimited: boolean
}

export type GetCookiesApiConfig = ApiConfig<
  undefined,
  undefined,
  GetCookiesPaths
>
export type GetCookiesApiRes = GetCookiesRes

//* *************************************************************************/
export type GetUserParams = { fetchAvatar?: boolean }
export type GetUserPaths = {
  userId: string
}

export type GetUserRes = User

export type GetUserApiConfig = ApiConfig<undefined, GetUserParams, GetUserPaths>
export type GetUserApiRes = GetUserRes

//* *************************************************************************/
export type GetUserPublicPaths = {
  userId: string
}
export type GetUserQueryParams = {
  fetchAvatar: boolean
}

export type GetUserPublicRes = {
  error?: string
  followerCount?: number
  followingCount?: number
  isFollowedByUser?: boolean
  isFollowingUser?: boolean
  user: UserPublic
}

export type GetUserPublicApiConfig = ApiConfig<
  undefined,
  GetUserQueryParams,
  GetUserPublicPaths
>
export type GetUserPublicApiRes = GetUserPublicRes

//* *************************************************************************/
export type GetTicketPaths = {
  eventId: string
  ticketId: string
}

export type GetTicketRes = {
  ticketId: string
  eventId: string
}

export type GetTicketApiConfig = ApiConfig<undefined, undefined, GetTicketPaths>
export type GetTicketApiRes = GetTicketRes

//* *************************************************************************/
export type GetTicketsApiConfig = ApiConfig<undefined, undefined, undefined>
export type GetTicketsApiRes = {
  tickets: Ticket[]
}

//* *************************************************************************/
export type SubmitFeedbackData = {
  ticketId?: string
  worldId?: string
  submission: string
}
export type SubmitFeedbackRes = any

export type SubmitFeedbackApiConfig = ApiConfig<SubmitFeedbackData>
export type SubmitFeedbackApiRes = SubmitFeedbackRes

//* *************************************************************************/
export type PatchUserData = { user: string }
export type PatchUserPaths = { userId: string }
export type PatchUserApiConfig = ApiConfig<
  PatchUserData,
  undefined,
  PatchUserPaths
>
export type PatchUserApiRes = any

//* *************************************************************************/
export type GetStreamInputsPaths = { streamId: string }
export type GetStreamInputsRes = {
  primary: string
  backup: string
}
export type GetStreamInputsApiConfig = ApiConfig<
  undefined,
  undefined,
  GetStreamInputsPaths
>
export type GetStreamInputsApiRes = GetStreamInputsRes

//* *************************************************************************/
export enum EStreamProvider {
  agora = 'agora',
  mux = 'mux',
}

export type Resolution = {
  height: number
  width: number
  bitrate: number
  fps: number
}

export type StartEventData = Event
export type StartEventPaths = { eventId: string }
export type StartEventBody = {
  provider?: EStreamProvider
  resolution?: Resolution
}
export type StartEventApiConfig = ApiConfig<
  StartEventBody,
  undefined,
  StartEventPaths
>
export type StartEventApiRes = StartEventData

//* *************************************************************************/
export type ResetEventData = Event
export type ResetEventPaths = { eventId: string }
export type ResetEventApiConfig = ApiConfig<
  undefined,
  undefined,
  ResetEventPaths
>
export type ResetEventApiRes = ResetEventData

//* *************************************************************************/
export type EndEventData = Event
export type EndEventPaths = { eventId: string }
export type EndEventBody = {
  provider?: EStreamProvider
}
export type EndEventApiConfig = ApiConfig<
  EndEventBody | undefined,
  undefined,
  EndEventPaths
>
export type EndEventApiRes = EndEventData

//* *************************************************************************/
export type PauseEventData = Event
export type PauseEventPaths = { eventId: string }
export type PauseEventApiConfig = ApiConfig<
  undefined,
  undefined,
  PauseEventPaths
>
export type PauseEventApiRes = PauseEventData

//* *************************************************************************/
export type PatchEventData = Partial<Event>
export type PatchEventPaths = { eventId: string }
export type PatchEventApiConfig = ApiConfig<
  PatchEventData,
  undefined,
  PatchEventPaths
>
export type PatchEventApiRes = any

//* *************************************************************************/
export type GetDemoSessionPaths = { artistBrandId: string }
export type GetDemoSessionRes = {
  event: Event
}
export type GetDemoSessionApiConfig = ApiConfig<
  undefined,
  undefined,
  GetDemoSessionPaths
>
export type GetDemoSessionApiRes = GetDemoSessionRes

//* *************************************************************************/
// Notifications
export type EventNotification = {
  createdAt?: string
  deletedAt?: string
  email?: string
  eventId?: string
  frequency?: string
  id?: string
  lastSent?: string
}
export type GetEventNotificationsPaths = { eventId: string }
export type GetEventNotificationsApiConfig = ApiConfig<
  undefined,
  undefined,
  GetEventNotificationsPaths
>
export type GetEventNotificationsApiRes = EventNotification[]

export type NotificationEmailForm = {
  email: string
  frequency: string
}

export type AddNotificationEmailApiConfig = ApiConfig<
  NotificationEmailForm,
  undefined,
  GetEventNotificationsPaths
>

export type UpdateNotificationEmailApiConfig = ApiConfig<
  NotificationEmailForm,
  undefined,
  { emailId: string }
>

export type DeleteNotificationEmailApiConfig = ApiConfig<
  undefined,
  undefined,
  { emailId: string }
>

export type SendNotificationEmailApiConfig = ApiConfig<
  { emailId: string },
  undefined,
  { eventId: string }
>

export type SuperuserRefreshEntityApiConfig = ApiConfig<
  undefined,
  undefined,
  { entityId: string }
>

//* *************************************************************************/
export type GetDemoSessionLeftPaths = { artistBrandId: string }
export type GetDemoSessionLeftRes = {
  activeDemoSession: {
    eventId: string
    id: string
    region: string
    startedAt: Date
    stoppedAt: Date | null
    streamId: string
  }
  totalTimeLeft: number
  totalTimeSpent: number
  activeDemoSessionExpectedEnd: Date
}
export type GetDemoSessionLeftApiConfig = ApiConfig<
  undefined,
  undefined,
  GetDemoSessionLeftPaths
>
export type GetDemoSessionLeftApiRes = GetDemoSessionLeftRes

//* *************************************************************************/
export type EndDemoSessonPaths = { eventId: string }
export type EndDemoSessonRes = any
export type EndDemoSessonApiConfig = ApiConfig<
  undefined,
  undefined,
  EndDemoSessonPaths
>
export type EndDemoSessonApiRes = GetDemoSessionLeftRes

//* *************************************************************************/
export type CreateVodData = { startSeconds: number; endSeconds: number }
export type CreateVodPaths = { eventId: string }
export type CreateVodRes = any
export type CreateVodApiConfig = ApiConfig<
  CreateVodData,
  undefined,
  CreateVodPaths
>
export type CreateVodApiRes = CreateVodRes

//* *************************************************************************/
export type SearchAllEntity =
  | 'usersName'
  | 'usersAll'
  | 'artistsName'
  | 'eventsRec'
  | 'eventsCurrent'
  | 'eventsPast'
  | 'usersMentioned'
  | 'worldsArtistName'

export type SearchAllUsersNameRes = {
  id: string
  slug: string
  email: string
  firstName?: string
  lastName?: string
  username?: string
  image?: string
}

type SearchAllUsersAllRes = {
  email: string
  firstName: string
  id: string
  image: string
  avatarImage: string
  lastName: string
  slug: string
  username: string
  verifiedCreator?: boolean
}

type SearchAllArtistsNameRes = {
  id: string
  userId: string
  slug: string
  artistName: string
  image?: string
}

export type SearchAllEventsRecRes = Record<string, never>

export type SearchAllEventsCurrentRes = Record<string, never>

export type SearchAllEventsPastRes = Record<string, never>

type SearchAllResMap<T extends SearchAllEntity> = T extends 'usersName'
  ? SearchAllUsersNameRes
  : T extends 'usersAll'
  ? SearchAllUsersAllRes
  : T extends 'artistsName'
  ? SearchAllArtistsNameRes
  : T extends 'eventsRec'
  ? SearchAllEventsRecRes
  : T extends 'eventsCurrent'
  ? SearchAllEventsCurrentRes
  : T extends 'eventsPast'
  ? SearchAllEventsPastRes
  : any

export type SearchAllData = undefined
export type SearchAllParams = {
  q: string
  entities: SearchAllEntity[]
}
export type SearchAllPaths = undefined
export type SearchAllRes = {
  [entity in SearchAllEntity]: SearchAllResMap<entity>[]
}

export type SearchAllApiConfig = ApiConfig<
  SearchAllData,
  SearchAllParams,
  SearchAllPaths
>

export type SearchAllApiRes = SearchAllRes

//* *************************************************************************/
export type SearchArtistsParams = { query: string }
export type SearchArtistsRes = Pick<
  ArtistBrandProps,
  'id' | 'displayName' | 'slug' | 'image'
>[]

export type SearchArtistsApiConfig = ApiConfig<
  undefined,
  SearchArtistsParams,
  undefined
>
export type SearchArtistsApiRes = SearchArtistsRes

//* *************************************************************************/
export type SwitchArtistBrandData = { artistBrandId: string }
export type SwitchArtistBrandRes = { token: string }

export type SwitchArtistBrandApiConfig = ApiConfig<SwitchArtistBrandData>
export type SwitchArtistBrandApiRes = SwitchArtistBrandRes

//* *************************************************************************/
export type PurchasedMerchsParams = { paymentInfoId: string } | any
export type PurchasedMerchsRes = PurchasedPhyicalGoods[]

export type PurchasedMerchsApiConfig = ApiConfig<
  undefined,
  PurchasedMerchsParams,
  undefined
>
export type PurchasedMerchsApiRes = PurchasedMerchsRes

//* *************************************************************************/
export type GetArtistPublicEventsPaths = { artistBrandId: string }
export type GetArtistPublicEventsRes = Event[]

export type GetArtistPublicEventsApiConfig = ApiConfig<
  undefined,
  undefined,
  GetArtistPublicEventsPaths
>

export type GetArtistPublicEventsApiRes = GetArtistPublicEventsRes

//* *************************************************************************/
export type GetSalesLineGraphParams = {
  tz?: string
  target?: string
  targetIds?: string
  dateRange?: '7_days' | '30_days' | '90_days' | 'all_time'
  csv?: 1
  refresh?: boolean
}
export type GetSalesLineGraphRes = {
  averageSalesPrice: AverageSalePrice[]
  merchSales: MerchSale[]
  ticketSalesPerDay: TicketSalePerDay[]
  topCountries: TopCountry[]
}

export type GetSalesLineGraphApiConfig = ApiConfig<
  undefined,
  GetSalesLineGraphParams
>

export type GetSalesLineGraphApiRes<D extends 'csv' | object = object> =
  D extends 'csv' ? string : GetSalesLineGraphRes

//* *************************************************************************/
export type GetSalesViewCountData = undefined
export type GetSalesViewCountParams = {
  target?: string
  targetIds?: string
  dateRange?: '7_days' | '30_days' | '90_days' | 'all_time'
  groupBy?: string
}
export type GetSalesViewCountPaths = undefined
export type GetSalesViewCountRes = {
  respData: { date: number; totalViews: number }[]
}

export type GetSalesViewCountApiConfig = ApiConfig<
  undefined,
  GetSalesViewCountParams
>

export type GetSalesViewCountApiRes = GetSalesViewCountRes

//* *************************************************************************/
export type AddPhoneData = {
  phoneNumber: string
  source: string
  artistBrandIdOrSlug: string
  countryCode: string
}
export type AddPhoneRes = Record<string, never>

export type AddPhoneApiConfig = ApiConfig<AddPhoneData>

export type AddPhoneApiRes = AddPhoneRes

//* *************************************************************************/
export type AddMarketingEmailData = {
  email: string
  artistBrandId?: string
  description: string
  userId?: string
}
export type AddMarketingEmailRes = {
  success?: string[]
}

export type AddMarketingEmailApiConfig = ApiConfig<AddMarketingEmailData>

export type AddMarketingEmailApiRes = AddMarketingEmailRes

//* *************************************************************************/
export type GetDemoConfigsPaths = Record<string, never>
export type GetDemoConfigsRes = {
  demos: Demo[]
}
export type GetDemoConfigsApiConfig = ApiConfig<
  undefined,
  undefined,
  GetDemoConfigsPaths
>
export type GetDemoConfigsApiRes = GetDemoConfigsRes

//* *************************************************************************/
export type RefundEventData = { artistMessage: string }
export type RefundEventParams = undefined
export type RefundEventPaths = { eventId: string }
export type RefundEventRes = { error?: string }

export type RefundEventApiConfig = ApiConfig<
  RefundEventData,
  RefundEventParams,
  RefundEventPaths
>

export type RefundEventApiRes = RefundEventRes

//* *************************************************************************/
export type UpdateMomentBackgroundImageRes = {
  newFile: string
}

export type UpdateArtistBrandPaths = {
  artistBrandId: string
}

export type UpdateMomentBackgroundImageConfig = ApiConfig<
  FormData,
  undefined,
  UpdateArtistBrandPaths
>

export type UpdateArtistBrandData = {
  backgroundImage?: string
  displayName?: string
  chatRateLimit?: number
  merch?: string
  spotify?: string
  soundcloud?: string
  applemusic?: string
  tidal?: string
  facebook?: string
  instagram?: string
  twitter?: string
  youtube?: string
}

export type UpdateArtistBrandRes = {
  success?: Array<any>
  message?: string
}

export type UpdateArtistBrandConfig = ApiConfig<
  UpdateArtistBrandData,
  undefined,
  UpdateArtistBrandPaths
>

export type DeleteCurrentMomentBackgroundRes = {
  success?: Array<any>
  message?: string
}

export type DeleteCurrentMomentBackgroundData = {
  backgroundImage?: string
}

export type DeleteCurrentMomentBackgroundConfig = ApiConfig<
  DeleteCurrentMomentBackgroundData,
  undefined,
  UpdateArtistBrandPaths
>

//* ****************************** MUTE/UNMUTE ************************************/

export type GetMutedUsersIdsData = undefined
export type GetMutedUsersIdsParams = {
  muteType: MuteType
  artistBrandIdOrSlug?: string
}
export type GetMutedUsersIdsPaths = undefined
export type GetMutedUsersIdsRes = string[]

export type GetMutedUsersIdsApiConfig = ApiConfig<
  GetMutedUsersIdsData,
  GetMutedUsersIdsParams,
  GetMutedUsersIdsPaths
>

export type GetMutedUsersIdsApiRes = GetMutedUsersIdsRes

//* *************************************************************************/
export type GetMutedUsersEnrichedResType = {
  artist: Partial<ArtistBrand>
  mutedUsers: UserProps[]
}

export type GetMutedUsersEnrichedData = undefined
export type GetMutedUsersEnrichedParams = {
  muteType: MuteType
  artistBrandIdOrSlug?: string
}
export type GetMutedUsersEnrichedPaths = undefined
export type GetMutedUsersEnrichedRes = GetMutedUsersEnrichedResType[]

export type GetMutedUsersEnrichedApiConfig = ApiConfig<
  GetMutedUsersEnrichedData,
  GetMutedUsersEnrichedParams,
  GetMutedUsersEnrichedPaths
>

export type GetMutedUsersEnrichedApiRes = GetMutedUsersEnrichedRes

//* *************************************************************************/
export type AddToModerationQueueData = {
  channel_id: string
  message_cleaned: string
  message_id: string
  message_raw: string
  source: string
  username: string
  world_domain: string
  world_id: string
  priority: number
  provider: string
  report_reason?: string
}
export type AddToModerationQueueParams = undefined
export type AddToModerationQueuePaths = undefined
export type AddToModerationQueueRes = {}

export type AddToModerationQueueApiConfig = ApiConfig<
  AddToModerationQueueData,
  AddToModerationQueueParams,
  AddToModerationQueueRes
>

export type AddToModerationQueueApiRes = AddToModerationQueueRes

//* *************************************************************************/
export type PostMuteUserData = {
  muteType: MuteType
  artistBrandIdOrSlug?: string
  userId: string
}
export type PostMuteUserParams = undefined
export type PostMuteUserPaths = undefined
export type PostMuteUserRes = { message: string }

export type PostMuteUserApiConfig = ApiConfig<
  PostMuteUserData,
  PostMuteUserParams,
  PostMuteUserPaths
>

export type PostMuteUserApiRes = PostMuteUserRes

//* *************************************************************************/
export type PostUnmuteUserData = {
  muteType: MuteType
  artistBrandIdOrSlug?: string
  userId: string
}
export type PostUnmuteUserParams = undefined
export type PostUnmuteUserPaths = undefined
export type PostUnmuteUserRes = { message: string }

export type PostUnmuteUserApiConfig = ApiConfig<
  PostUnmuteUserData,
  PostUnmuteUserParams,
  PostUnmuteUserPaths
>

export type PostUnmuteUserApiRes = PostUnmuteUserRes
//* *************************************************************************/
export type SetBundlePublicData = { isPublic: boolean }
export type SetBundlePublicPaths = { bundleId: string }
export type SetBundlePublicRes = {
  error?: string
  merchs?: CheckoutPhysicalGoods[]
}

export type SetBundlePublicApiConfig = ApiConfig<
  SetBundlePublicData,
  undefined,
  SetBundlePublicPaths
>

export type SetBundlePublicApiRes = SetBundlePublicRes

//* *************************************************************************/
export type ReportArtistUserData = {
  userId: string
  isSuper: boolean
  msgDetails: { channel: string; timeToken: string }
}
export type ReportArtistUserParams = undefined
export type ReportArtistUserPaths = {
  artistBrandIdOrSlug: string
  reportUserIdOrSlug: string
}
export type ReportArtistUserRes = { message: string }

export type ReportArtistUserApiConfig = ApiConfig<
  ReportArtistUserData,
  ReportArtistUserParams,
  ReportArtistUserPaths
>

export type ReportArtistUserApiRes = ReportArtistUserRes

//* *************************************************************************/
export type SpotifyLoginMethod = {
  accessToken: string
  refreshToken: string
  profileId: string
}

export type SpotifyLoginMethodApiConfig = ApiConfig<
  undefined,
  undefined,
  { profileId: string }
>

export type SpotifyLoginMethodApiRes = SpotifyLoginMethod

//* *************************************************************************/
export type ReportedByMsgDetails = {
  channel: string
  timeToken: string
}

export type ReportedBy = {
  datetime?: Date
  msgDetails: ReportedByMsgDetails
  userId: string
  isSuper: boolean
}

export type ReportedUserDetails = {
  reportedBy: ReportedBy[]
  artistBrandId?: string
  reportedUserId: string
}

export type ReportSummary = {
  reportedUserId: string
  reportCount: number
  channels: string[]
}

export type ReportedUsersWithSummary = {
  artistBrandId: string
  reportedUsers: ReportedUserDetails[]
  reportSummary: ReportSummary[]
}

export type ArtistsWithReportedUsersByArtistBrandIdsData = undefined
export type ArtistsWithReportedUsersByArtistBrandIdsParams = undefined
export type ArtistsWithReportedUsersByArtistBrandIdsPaths = {
  artistBrandIds: string
}
export type ArtistsWithReportedUsersByArtistBrandIdsRes =
  ReportedUsersWithSummary[]

export type ArtistsWithReportedUsersByArtistBrandIdsApiConfig = ApiConfig<
  ArtistsWithReportedUsersByArtistBrandIdsData,
  ArtistsWithReportedUsersByArtistBrandIdsParams,
  ArtistsWithReportedUsersByArtistBrandIdsPaths
>

export type ArtistsWithReportedUsersByArtistBrandIdsApiRes =
  ArtistsWithReportedUsersByArtistBrandIdsRes

//* *************************************************************************/
export type GetArtistBrandData = undefined
export type GetArtistBrandParams = undefined
export type GetArtistBrandPaths = {
  artistBrandIdOrSlug: string
}
export type GetArtistBrandRes = ArtistBrandProps

export type GetArtistBrandApiConfig = ApiConfig<
  GetArtistBrandData,
  GetArtistBrandParams,
  GetArtistBrandPaths
>

export type GetArtistBrandApiRes = GetArtistBrandRes

//* *************************************************************************/
export type GetArtistBrandCreatorsData = undefined
export type GetArtistBrandCreatorsParams = undefined
export type GetArtistBrandCreatorsPaths = { artistBrandIdOrSlug: string }
export type GetArtistBrandCreatorsRes = {
  artistBrand: ArtistBrand
  creators: User[]
  isFollowingArtist: boolean
}

export type GetArtistBrandCreatorsApiConfig = ApiConfig<
  GetArtistBrandCreatorsData,
  GetArtistBrandCreatorsParams,
  GetArtistBrandCreatorsPaths
>

export type GetArtistBrandCreatorsApiRes = GetArtistBrandCreatorsRes

//* *************************************************************************/
export type GetArtistBrandPublicPaths = { userId: string }
export type GetArtistBrandPublicRes = ArtistBrand

export type GetArtistBrandPublicApiConfig = ApiConfig<
  undefined,
  undefined,
  GetArtistBrandPublicPaths
>

export type GetArtistBrandPublicApiRes = GetArtistBrandPublicRes

//* *************************************************************************/
export type ArtistPropsWithReportedUser = {
  artistBrand: ArtistBrandProps
  reportedUsers: UserProps & { channels: string[]; reportedCount: number }
}

export type GetArtistsWithReportedUsersData = undefined
export type GetArtistsWithReportedUsersParams = undefined
export type GetArtistsWithReportedUsersPaths = undefined
export type GetArtistsWithReportedUsersRes = ArtistPropsWithReportedUser[]

export type GetArtistsWithReportedUsersApiConfig = ApiConfig<
  GetArtistsWithReportedUsersData,
  GetArtistsWithReportedUsersParams,
  GetArtistsWithReportedUsersPaths
>

export type GetArtistsWithReportedUsersApiRes = GetArtistsWithReportedUsersRes

//* *************************************************************************/
export type GetReportedUsersByArtistData = undefined
export type GetReportedUsersByArtistParams = undefined
export type GetReportedUsersByArtistPaths = {
  artistBrandIdOrSlug: string
}
export type GetReportedUsersByArtistRes = {
  artistBrandId: string
  reportedUsers: ReportedUserDetails[]
}

export type GetReportedUsersByArtistApiConfig = ApiConfig<
  GetReportedUsersByArtistData,
  GetReportedUsersByArtistParams,
  GetReportedUsersByArtistPaths
>

export type GetReportedUsersByArtistApiRes = GetReportedUsersByArtistRes

//* *************************************************************************/
export type UpdateTicketMasterPaths = { worldId: string }
export type UpdateTicketMasterRes = EventAndSuccessApiRes
export type UpdateTicketMasterReqBody = FormData

export type UpdateTicketMasterApiConfig = ApiConfig<
  UpdateTicketMasterReqBody,
  undefined,
  UpdateTicketMasterPaths
>
export type UpdateTicketMasterApiRes = UpdateTicketMasterRes

//* *************************************************************************/
export type UpdateShopifyStorePaths = { worldId: string }
export type UpdateShopifyStoreRes = EventAndSuccessApiRes
export type UpdateShopifyStoreReqBody = FormData

export type UpdateShopifyStoreApiConfig = ApiConfig<
  UpdateShopifyStoreReqBody,
  undefined,
  UpdateShopifyStorePaths
>
export type UpdateShopifyStoreApiRes = UpdateShopifyStoreRes

//* *************************************************************************/
export type AddShopifyProductPaths = { worldId: string; storeId: string }
export type AddShopifyProductRes = EventAndSuccessApiRes
export type AddShopifyProductReqBody = { productId: string }

export type AddShopifyProductApiConfig = ApiConfig<
  AddShopifyProductReqBody,
  undefined,
  AddShopifyProductPaths
>
export type AddShopifyProductApiRes = AddShopifyProductRes

//* *************************************************************************/
export type DeleteShopifyProductPaths = { worldId: string; storeId: string }
export type DeleteShopifyProductRes = EventAndSuccessApiRes
export type DeleteShopifyProductReqBody = { productId: string }

export type DeleteShopifyProductApiConfig = ApiConfig<
  DeleteShopifyProductReqBody,
  undefined,
  DeleteShopifyProductPaths
>
export type DeleteShopifyProductApiRes = DeleteShopifyProductRes

//* *************************************************************************/
export type SignUpData = {
  email: string
  username: string
  password: string
  receiveUpdates?: boolean
  timezone: string
  avatar?: EncodedAvatarConfig
  avatarImage?: string
  worldId?: string
  worldsOnboarding?: boolean
  autoFollowId?: string
  captchaToken?: string
}
export type SignUpParams = undefined
export type SignUpPaths = undefined
export type SignUpRes = Record<string, never>

export type SignUpApiConfig = ApiConfig<SignUpData, SignUpParams, SignUpPaths>

export type SignUpApiRes = SignUpRes

//* *************************************************************************/
export type CheckEmailData = { email: string; captchaToken?: string }
export type CheckEmailParams = undefined
export type CheckEmailPaths = undefined
export type CheckEmailRes = { hasAccount?: boolean }

export type CheckEmailApiConfig = ApiConfig<
  CheckEmailData,
  CheckEmailParams,
  CheckEmailPaths
>

export type CheckEmailApiRes = CheckEmailRes

//* *************************************************************************/
export type GenerateEvTicketData = {
  eventId: string
  ticketType: 'free'
}
export type GenerateEvTicketParams = undefined
export type GenerateEvTicketPaths = undefined
export type GenerateEvTicketRes = Ticket

export type GenerateEvTicketApiConfig = ApiConfig<
  GenerateEvTicketData,
  GenerateEvTicketParams,
  GenerateEvTicketPaths
>

export type GenerateEvTicketApiRes = GenerateEvTicketRes

//* *************************************************************************/
export type SearchCuratedEventsPaths = {
  language: string
}

export type SearchCuratedEventsParams = {
  displayType: 'PREMIERE' | 'PREVIOUS'
}

export type SearchCuratedEventsRes = {
  events: Event[]
}

export type SearchCuratedEventsApiConfig = ApiConfig<
  undefined,
  SearchCuratedEventsParams,
  SearchCuratedEventsPaths
>

//* *************************************************************************/
export type GetCartData = undefined
export type GetCartParams = { artistBrandId: string }
export type GetCartPaths = { id: string }
export type GetCartRes = { shoppingBag: BagItem[] }

export type GetCartApiConfig = ApiConfig<
  GetCartData,
  GetCartParams,
  GetCartPaths
>

export type GetCartApiRes = GetCartRes

//* *************************************************************************/
export type SetCartData = {
  id?: string
  artistBrandId: string
  shoppingBag: BagItem[]
}
export type SetCartParams = undefined
export type SetCartPaths = undefined
export type SetCartRes = { id: string }

export type SetCartApiConfig = ApiConfig<
  SetCartData,
  SetCartParams,
  SetCartPaths
>

export type SetCartApiRes = SetCartRes

//* *************************************************************************/

export type DefaultCurrency = {
  countryName: string
  currencyCode: string
}

export type LocationData = {
  city: string
  continent: string
  country: string
  countryCode: string
  ip: string
  lat: number
  lon: number
  state: string
  status: string
  timezone: string
  zip: string
}

export type Rate = {
  rate: number
  type: 'decimal' | 'zero-decimal'
}

export type getCurrenciesData = undefined
export type getCurrenciesParams = { eventSlug?: string }
export type getCurrenciesPaths = undefined
export type getCurrenciesRes = {
  defaultCurrency: DefaultCurrency
  rates: { [key: string]: Rate }
  locationData: LocationData
}

export type getCurrenciesApiConfig = ApiConfig<
  getCurrenciesData,
  getCurrenciesParams,
  getCurrenciesPaths
>

export type getCurrenciesApiRes = getCurrenciesRes

//* *************************************************************************/
export type getIdentityData = undefined
export type getIdentityParams = { isArtistBrand?: boolean }
export type getIdentityPaths = { id?: string }
export type getIdentityRes = {
  patreon: PatreonAccount
  campaign: PatreonCampaign
}

export type getIdentityApiConfig = ApiConfig<
  getIdentityData,
  getIdentityParams,
  getIdentityPaths
>

export type getIdentityApiRes = getIdentityRes

//* *************************************************************************/
export type GetUsersWithPermissionsPaths = { artistBrandId: string }
export type GetUsersWithPermissionsRes = { users: User }

export type GetUsersWithPermissionsApiConfig = ApiConfig<
  undefined,
  undefined,
  GetUsersWithPermissionsPaths
>
export type GetUsersWithPermissionsApiRes = GetUsersWithPermissionsRes

//* *************************************************************************/
export type GetMomentData = undefined
export type GetMomentParams = undefined
export type GetMomentPaths = { eventIdOrSlug: string }
export type GetMomentRes = {
  artistBrand: GetArtistBrandPublicRes
  currencyObject: getCurrenciesRes
  eventAndStream: GetEventRes

  error?: string /**

   Set when unable to fetch the cache

   localforage.setItem(`${eventSlug}-moment`, {
      error: 'Unable to load moment',
    }).then((res) => res)

  */
}

export type GetMomentApiConfig = ApiConfig<
  GetMomentData,
  GetMomentParams,
  GetMomentPaths
>

export type GetMomentApiRes = GetMomentRes

//* *************************************************************************/
export type UserPreferenceType = {
  isOn?: boolean
  volume?: number
}

export type WorldPreferenceType = {
  push?: boolean
  email?: boolean
}

export type WorldPreferences = {
  appearanceEvents: WorldPreferenceType
  comentsAndReplies: WorldPreferenceType
  upvotes: WorldPreferenceType
  creatorEngagement: WorldPreferenceType
  mention: WorldPreferenceType
  newMessage: WorldPreferenceType
  announcements: WorldPreferenceType
}

export type SystemPreferences = {
  follows: UserPreferenceType
  directMessage: UserPreferenceType
  systemSounds: UserPreferenceType
  environmentalSounds?: UserPreferenceType
  backgroundAudio?: UserPreferenceType
}
