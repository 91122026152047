'use client'

import {
  clearToken,
  getAnonUUID,
  getOriginalToken,
  getToken,
  setOriginalToken,
  setToken,
  setUUID,
} from '@momenthouse/cookies'
import {
  ArtistBrandProps as ArtistBrand,
  AvatarProps,
  RecaptchaAction,
  UserBanType,
  UserProps as User,
  UserPublic,
} from '@momenthouse/types'
import { superUserChecker } from '@momenthouse/utils/src/user'
import * as Sentry from '@sentry/nextjs'
import { AxiosResponse } from 'axios'
import { isEmpty, isFunction, some } from 'lodash'
import { includes } from 'lodash'
import moment from 'moment'
import { ReCaptchaProvider, useReCaptcha } from 'next-recaptcha-v3'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  attemptResignJwtToken,
  getArtistBrandData,
  getUserProfile,
  signInWithEmailandPassword,
  signUp,
} from '../api'
import { ICallback, ISignInWithEmail, ISignUp } from '../types'
import decodeJWTCustom from '../utils/jwtUtils'
import { PermissionEnum } from './enums'

export interface AuthProviderState {
  isSigningIn: boolean
  isSigningUp: boolean
  isFetchingUser: boolean
}

export interface AuthContextParams extends AuthProviderState {
  isLoggedIn: boolean
  // TODO
  user: any
  isGuest: boolean
  // TODO
  artistBrand: any //ArtistBrandProps | null
  artistBrandId?: string | null

  error: string | null

  handleFetchUser: any //() => Promise<User | null>
  handleSignIn: any //(config: IHandleSignInConfig) => Promise<void | User>
  handleSignUp: any //(payload: any, cb?: ICallback) => Promise<void | User>
  handleSignOut: any //(cb?: ICallback) => void
  handlePasswordReset: (cb?: ICallback) => void
  hasPermission: any
  setNotLoggedIn: () => void

  updateUser: (user: any) => void
  userPermissions: any

  impersonate: any
  isImpersonating: any
  stopImpersonate: any

  isSuper: boolean

  // Leftover references | todo
  getArtistBrand: any
  refreshUserData: any
  removeUser: any
  updateArtistBrand: any
  refreshArtistBrandData: any
  setError: any
  userId: any
  artistBrandLoading: any
  isTokenChecked: boolean // determine if token already check
  processLoginResponse: (data: any) => void
}

export const AuthContext = createContext<AuthContextParams>(
  {} as AuthContextParams
)

const initialState = {
  isSigningIn: false,
  isSigningUp: false,
  isFetchingUser: false,
}

type AuthProviderProps = {
  children: ReactNode
  initialUser?: User
  defaultAvatarConfig?: AvatarProps
}

function AuthContextProvider({
  children,
  initialUser,
  defaultAvatarConfig,
}: AuthProviderProps) {
  const [artistBrand, setArtistBrand] = useState<ArtistBrand | null>(null)
  const [artistBrandLoading, setArtistBrandLoading] = useState(false)
  const [state, setState] = useState<AuthProviderState>(initialState)
  const [user, setUser] = useState<User | null>(initialUser)
  const [isTokenChecked, setIsTokenChecked] = useState<boolean>(
    !!initialUser?.id
  )
  const [signedIn, setSignedIn] = useState<boolean>(!!initialUser?.id)
  const [userLoading, setUserLoading] = useState<boolean>(!initialUser?.id)
  const currentToken = getToken()
  const originalToken = getOriginalToken()

  const isImpersonating =
    originalToken !== null && originalToken !== currentToken

  const [error, setError] = useState<string | null>(null)

  const { executeRecaptcha } = useReCaptcha()

  const handleStateUpdate = (payload: Partial<AuthProviderState>) => {
    setState((prev) => ({ ...prev, ...payload }))
  }

  const updateUser = (incoming: User) => {
    // TODO: Add call to update user in DB
    if (!isEmpty(incoming)) {
      setUser((oldUser) => ({ ...oldUser, ...incoming }))
    }

    setIsTokenChecked(true)
    setUserLoading(false)
  }

  const handleSignInError = (response: any) => {
    switch (response.status) {
      case 400:
        setError('Account does not exist.')
        break
      case 401:
        if (response?.data?.error?.includes('Account is locked')) {
          setError('Account is locked, please reset password to login.')
        } else {
          setError('Incorrect email / password')
        }
        break
      case 404:
        setError('Account does not exist.')
        break
      case 500:
        if (response?.data?.error) {
          setError(response?.data?.error)
          break
        }

        setError('Something went wrong, please try again later')
        break
      default:
        setError('Something went wrong, please try again later')
    }
  }

  const setTokenResponse = async (token: string, userData: any) => {
    const { artistBrandId } = decodeJWTCustom(token)
    const newUserData = { ...userData, artistBrandId }
    /** Prevent sign-in if the user has been system-wide banned */
    if (newUserData?.globalBanned) {
      return newUserData as User
    }
    setUUID(newUserData.id)
    setToken(token)
    setOriginalToken(token)

    if (artistBrandId) {
      await getArtistBrand(artistBrandId)
    }

    updateUser(newUserData)
    setSignedIn(true)
    return newUserData as User
  }

  const processLoginResponse = async (res: AxiosResponse<any>) => {
    const data = await res.data
    const userData = data.user
    return await setTokenResponse(data?.token, userData)
  }

  const processSignupResponse = async (res: AxiosResponse<any>) => {
    switch (res.status) {
      case 200: {
        const data = await res.data
        const userData = data.user
        const { artistBrandId } = decodeJWTCustom(data.token)
        const newUserData = { ...userData, artistBrandId }
        updateUser(newUserData)
        setUUID(newUserData.id)
        setToken(data.token)
        setOriginalToken(data?.token)

        if (artistBrandId) {
          await getArtistBrand(artistBrandId)
        }

        setSignedIn(true)
        return newUserData as User
      }
      case 400:
        setError('Something went wrong, please try again later')
        return null
      case 409:
        setError('Account already exists.')
        return null
      case 500:
        setError('Something went wrong, please try again later')
        return null
      default:
        return null
    }
  }

  const handleFetchUser = async (): Promise<User | null> => {
    try {
      setError(null)
      setIsTokenChecked(false)
      handleStateUpdate({ isFetchingUser: true })
      const token = getToken()
      let jWTData = decodeJWTCustom(token)
      if (jWTData.isExpired) {
        const newJwtData = await attemptResignJwtToken()
        if (newJwtData) {
          return await setTokenResponse(newJwtData.token, newJwtData.user)
        }
      }

      const { userId, artistBrandId } = jWTData
      if (!userId) return null
      setUUID(userId)

      const res = await getUserProfile(userId)
      const data = await res.data
      const newUserData = { ...data, artistBrandId }

      /** Force sign-out if the user was banned while away from session */
      if (newUserData?.globalBanned) {
        handleSignOut()
        return null
      }

      if (res.status === 200) {
        updateUser(newUserData)
        setSignedIn(true)
        return newUserData as User
      }

      return null
    } catch (error) {
      Sentry.captureException(error, {
        tags: { component: 'useAuth', function: 'handleFetchUser' },
      })
      return null
    } finally {
      handleStateUpdate({ isFetchingUser: false })
    }
  }

  const handleSignIn: AuthContextParams['handleSignIn'] = async (
    config,
    callback: any
  ) => {
    let res
    let user: User | null | undefined
    setError(null)
    handleStateUpdate({ isSigningIn: true })
    setIsTokenChecked(false)
    try {
      const captchaToken = await executeRecaptcha(RecaptchaAction.SIGNIN)
      res = await signInWithEmailandPassword({
        ...config.payload,
        captchaToken,
      } as ISignInWithEmail)
      user = await processLoginResponse(res)
      if (config?.payload?.shopifyLogin) {
        localStorage.setItem('MH_SHOPIFY_JWT', res?.data?.token)
      } else {
        localStorage.removeItem('MH_SHOPIFY_JWT')
      }
      if (isFunction(callback)) {
        callback(user, error)
      }
    } catch (e: any) {
      Sentry.captureException(e, {
        tags: { component: 'useAuth', function: 'handleSignIn' },
      })
      handleSignInError(e.response)
    } finally {
      handleStateUpdate({ isSigningIn: false })
    }

    handleStateUpdate({ isSigningIn: false })
    return user
  }

  const handleSignOut: AuthContextParams['handleSignOut'] = async (
    callback: Function
  ) => {
    setError(null)
    setUser(null)
    setSignedIn(false)
    setArtistBrand(null)
    clearToken()
    setIsTokenChecked(false)

    if (isFunction(callback)) {
      callback()
    }
  }

  const setNotLoggedIn = () => {
    setSignedIn(false)
  }

  const handlePasswordReset: AuthContextParams['handlePasswordReset'] =
    async () => {}

  const handleSignUp: AuthContextParams['handleSignUp'] = async (
    payload: ISignUp | any,
    callback?: (userData: User | null, error: Error | null | string) => void,
    /** additional config */
    config?: {
      /** send the request but skip signing in */
      skipSignin?: boolean
    }
  ) => {
    let userData: User | null

    try {
      handleStateUpdate({ isSigningUp: true })
      setError(null)
      const captchaToken = await executeRecaptcha(RecaptchaAction.SIGNUP)
      const res = await signUp({ ...payload, captchaToken })

      if (!config?.skipSignin) {
        userData = await processSignupResponse(res)
        callback && callback(userData, error)
      }
    } catch (err) {
      if (err?.response?.data?.error) setError(err?.response?.data?.error)
      userData = err.response
    } finally {
      handleStateUpdate({ isSigningUp: false })
    }

    return userData
  }

  const refreshUserData = async () => {
    if (userLoading) {
      return
    }

    setUserLoading(true)
    const refreshedUser = await handleFetchUser()
    setUserLoading(false)
    return refreshedUser
  }

  const refreshArtistBrandData = async () => {
    const artistBrandId = user?.artistBrandId

    return await getArtistBrand(artistBrandId)
  }

  const removeUser = (e?: any) => {
    e?.preventDefault()
    setUser(null)
    setSignedIn(false)
  }

  const getArtistBrand = async (artistBrandIdOrSlug: string | undefined) => {
    if (artistBrandIdOrSlug) {
      try {
        setArtistBrandLoading(true)
        const res = await getArtistBrandData(artistBrandIdOrSlug)
        const artistBrand = res?.data || null

        if (artistBrand) {
          if (
            user?.artistBrandId === artistBrandIdOrSlug ||
            user?.id === artistBrandIdOrSlug
          ) {
            updateArtistBrand(artistBrand)
          }
        }

        return artistBrand
      } catch (error) {
        console.error('getArtistBrand:', error)
        Sentry.captureException(error, {
          tags: { component: 'useAuth', function: 'getArtistBrand' },
        })
      } finally {
        setArtistBrandLoading(false)
      }
    } else {
      setArtistBrandLoading(false)
      console.error('getArtistBrand:', 'no artistBrandIdOrSlug provided')
    }
  }

  const updateArtistBrand = (artistBrandData: Partial<ArtistBrand>) => {
    if (!isEmpty(artistBrandData)) {
      setArtistBrand(
        (current) => ({ ...current, ...artistBrandData } as ArtistBrand)
      )
    }
  }

  const impersonate = async (token: string) => {
    setToken(token)
    if (typeof window !== 'undefined') {
      window.location.reload()
    }
  }

  const stopImpersonate = async () => {
    if (isImpersonating && originalToken) {
      setToken(originalToken)

      if (typeof window !== 'undefined') {
        window.location.reload()
      }
    } else {
      handleSignOut()
    }
  }

  const hasPermission = (permission: PermissionEnum) => {
    return includes(user?.permissions, permission)
  }

  useEffect(() => {
    if (initialUser?.id) return

    const token = getToken()
    if (!!token) {
      if (isEmpty(user)) {
        handleFetchUser()
      }
    } else {
      setIsTokenChecked(true)
    }
  }, [initialUser?.id, user])

  useEffect(() => {
    if (user?.banType === UserBanType.BANNED && user?.banUntil) {
      // Let muted users sign in
      const currentlyBanned = moment.utc(user.banUntil).isAfter(moment.utc())
      if (currentlyBanned) {
        handleSignOut()
      }
    }
    if (user?.globalBanned) {
      handleSignOut()
    }
  }, [user?.globalBanned, user?.banType])

  useEffect(() => {
    if (isEmpty(artistBrand) && user?.artistBrandId) {
      refreshArtistBrandData()
    }
  }, [user?.artistBrandId, artistBrand])

  const memoedValue: AuthContextParams = useMemo(
    () => ({
      ...state,
      artistBrandId: user?.artistBrandId,
      artistBrand: artistBrand,
      error,
      setError,
      isLoggedIn: signedIn || Boolean(user?.id),
      refreshUserData,
      isTokenChecked,
      isSuper: superUserChecker(user) || false,
      isGuest: !Boolean(user?.id),

      // impersonation
      impersonate,
      isImpersonating,
      stopImpersonate,

      // Actions
      handlePasswordReset,
      handleSignIn,
      handleSignOut,
      handleSignUp,
      handleFetchUser,
      setNotLoggedIn,
      removeUser,
      updateUser,
      user,
      userId: user?.id,
      userPermissions: user?.permissions || [],
      hasPermission,
      getArtistBrand,
      refreshArtistBrandData,
      updateArtistBrand,
      processLoginResponse,
      // Leftover references | todo
      // check usecases of this flag
      artistBrandLoading,
    }),
    [
      state,
      user,
      artistBrandLoading,
      userLoading,
      artistBrand,
      error,
      isTokenChecked,
      processLoginResponse,
    ]
  )

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  )
}

export default function AuthProvider(p: AuthProviderProps) {
  return (
    <ReCaptchaProvider useEnterprise>
      <AuthContextProvider {...p} />
    </ReCaptchaProvider>
  )
}

export const useAuth = () => useContext(AuthContext)
