export default function getURL() {
  const isLocal = !JSON.parse(
    JSON.stringify(process.env.NEXT_PUBLIC_VERCEL_URL || '')
  )

  const assignedDomain = JSON.parse(
    JSON.stringify(process.env.NEXT_PUBLIC_ASSIGNED_DOMAIN || '')
  )

  const protocol = isLocal ? 'http://' : 'https://'
  const port = isLocal ? `:${process.env.PORT || 3000}` : ''

  let domain
  if (assignedDomain) {
    domain = assignedDomain
  } else if (process.env.NEXT_PUBLIC_VERCEL_URL) {
    domain = process.env.NEXT_PUBLIC_VERCEL_URL
  } else {
    domain = 'localhost'
  }

  return `${protocol}${domain}${port}`
}
