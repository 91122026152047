import {
  CHANNEL_MEMBERSHIP_KEY,
  UNREAD_NOTIF_ID_STORE_KEY,
} from '@momenthouse/constants'
import Cookies, { CookieAttributes } from 'js-cookie'

import Cookie from './cookie'

const MH_COOKIE_DOMAIN = process.env.NEXT_PUBLIC_MH_COOKIE_DOMAIN

export const AUTH_TOKEN_KEY = 'MH_AUTH_JWT_05_22'
export const ANON_UUID_KEY = 'MH_ANON_UUID'
export const UUID_KEY = 'MH_UUID'
export const COOKIE_CONSENT_KEY = 'MH_COOKIE_CONSENT'
export const SELECTED_ADMIN_ARTIST_BRAND_KEY = 'SELECTED_ADMIN_ARTIST_BRAND_KEY'

const MH_AUTH_COOKIE_CONFIG: CookieAttributes = {
  domain: MH_COOKIE_DOMAIN,
  expires: 365,
  secure: process.env.VERCEL === '1',
}

export const MHCookies = new Cookie(MH_AUTH_COOKIE_CONFIG)

declare global {
  interface Window {
    // The native app may push in a global variable to sync the auth token
    WORLD_EXTERNAL_TOKEN?: string
  }
}

/**
 * JWT Token used for authentication / login
 */
export const getToken = (): string | undefined => {
  if (
    typeof window !== 'undefined' &&
    typeof window.WORLD_EXTERNAL_TOKEN === 'string'
  ) {
    return window.WORLD_EXTERNAL_TOKEN
  }
  return MHCookies.get(AUTH_TOKEN_KEY)
}

export const getOriginalToken = (): string | undefined => {
  return MHCookies.get(AUTH_TOKEN_KEY + '_ORIGINAL')
}

export const getCookieConsent = (): string | undefined => {
  return Cookies.get(COOKIE_CONSENT_KEY)
}

export const setUUID = (uuid: string) => {
  return MHCookies.set(UUID_KEY, uuid)
}

export const getAnonUUID = (): string | undefined => {
  return Cookies.get(ANON_UUID_KEY)
}

export const getUUID = (): string | undefined => {
  return Cookies.get(UUID_KEY)
}

export const getSelectedAdminArtistBrand = (): string | undefined => {
  return Cookies.get(SELECTED_ADMIN_ARTIST_BRAND_KEY)
}

/**
 * Sets both keys to allow for 1 login across both world.co and momenthouse.com domains
 */
export const setToken = (token: string) => {
  return MHCookies.set(AUTH_TOKEN_KEY, token)
}

export const setOriginalToken = (token: string) => {
  return MHCookies.set(AUTH_TOKEN_KEY + '_ORIGINAL', token)
}

export const setSelectedAdminArtistBrand = (
  payload: any
): string | undefined => {
  return Cookies.set(SELECTED_ADMIN_ARTIST_BRAND_KEY, JSON.stringify(payload), {
    secure: process.env.VERCEL === '1',
  })
}

export const clearToken = (): void => {
  Cookies.remove('MH_JWT')
  Cookies.remove('MH_CART_UUID')
  Cookies.remove('MH_CART_ID')
  MHCookies.delete(AUTH_TOKEN_KEY)
  MHCookies.delete(AUTH_TOKEN_KEY + '_ORIGINAL')
  MHCookies.delete('MH_DAILY_PASSWORD')
  localStorage.removeItem('MH_SHOPIFY_JWT')
  localStorage.removeItem(CHANNEL_MEMBERSHIP_KEY)
  localStorage.removeItem(UNREAD_NOTIF_ID_STORE_KEY)
  return
}
