import {
  AccessCodeDropDateApiConfig,
  AddDigitalAddressApiConfig,
  AddDigitalAddressRes,
  AddMarketingEmailApiConfig,
  AddMarketingEmailApiRes,
  AddMerchsApiConfig,
  AddMerchsApiRes,
  AddNewGuestApiConfig,
  AddNewGuestApiRes,
  AddNotificationEmailApiConfig,
  AddPhoneApiConfig,
  AddPhoneApiRes,
  AddShippingAddressApiConfig,
  AddShippingAddressApiRes,
  AddShopifyProductApiConfig,
  AddShopifyProductApiRes,
  AddSongApiConfig,
  AddSongApiRes,
  AddTierApiConfig,
  AddTierApiRes,
  AddToModerationQueueApiConfig,
  AddToModerationQueueApiRes,
  ApiConfig,
  ChangePasswordApiConfig,
  ChangePasswordApiRes,
  CheckEmailApiConfig,
  CheckEmailApiRes,
  ConfirmAddOnPayIntentApiConfig,
  ConfirmAddOnPayIntentApiRes,
  ConfirmClivePayIntentApiConfig,
  ConfirmMerchGalleryPayIntentApiConfig,
  ConfirmMerchGalleryPayIntentApiRes,
  ConfirmPayIntentApiConfig,
  ConfirmPayIntentApiRes,
  ConfirmTippingPayIntentApiConfig,
  ConfirmTippingPayIntentApiRes,
  CountCodeApiConfig,
  CountCodeApiRes,
  CreateAccessCodeApiConfig,
  CreateAccessCodeApiRes,
  CreateBundlesApiConfig,
  CreateBundlesApiRes,
  CreateGeofenceApiConfig,
  CreateGeofenceApiRes,
  CreateVodApiConfig,
  CreateVodApiRes,
  DeleteAccessCodeApiConfig,
  DeleteAddOnApiConfig,
  DeleteAddOnApiRes,
  DeleteBundleApiConfig,
  DeleteBundleApiRes,
  DeleteBundleNewApiConfig,
  DeleteBundleNewApiRes,
  DeleteGeofenceApiConfig,
  DeleteGuestApiConfig,
  DeleteMerchApiConfig,
  DeleteMerchApiRes,
  DeleteNotificationEmailApiConfig,
  DeleteOwnAccountApiConfig,
  DeleteOwnAccountApiRes,
  DeletePayMentMethodApiConfig,
  DeletePayMentMethodApiRes,
  DeleteShippingAddressApiConfig,
  DeleteShippingAddressApiRes,
  DeleteShopifyProductApiConfig,
  DeleteShopifyProductApiRes,
  DeleteSongApiConfig,
  DeleteSongApiRes,
  DeleteTierApiConfig,
  EndDemoSessonApiConfig,
  EndDemoSessonApiRes,
  EndEventApiConfig,
  EndEventApiRes,
  ExportExternalAddOnPurchaseApiConfig,
  ExportExternalAddOnPurchaseReqRes,
  ForgotPasswordApiConfig,
  ForgotPasswordApiRes,
  GenerateEvTicketApiConfig,
  GenerateEvTicketApiRes,
  GetArtistBrandApiConfig,
  GetArtistBrandApiRes,
  GetArtistBrandCreatorsApiConfig,
  GetArtistBrandCreatorsApiRes,
  GetArtistBrandPublicApiConfig,
  GetArtistBrandPublicApiRes,
  GetArtistPublicEventsApiConfig,
  GetArtistPublicEventsApiRes,
  GetArtistsWithReportedUsersApiConfig,
  GetArtistsWithReportedUsersApiRes,
  GetCartApiConfig,
  GetCartApiRes,
  GetCookiesApiConfig,
  GetCookiesApiRes,
  GetCSVCodeApiConfig,
  getCurrenciesApiConfig,
  getCurrenciesApiRes,
  GetDemoConfigsApiConfig,
  GetDemoConfigsApiRes,
  GetDemoSessionApiConfig,
  GetDemoSessionApiRes,
  GetDemoSessionLeftApiConfig,
  GetDemoSessionLeftApiRes,
  GetEventApiConfig,
  GetEventApiRes,
  GetMerchsApiConfig,
  GetMerchsApiRes,
  GetMomentApiConfig,
  GetMomentApiRes,
  GetMutedUsersEnrichedApiConfig,
  GetMutedUsersEnrichedApiRes,
  GetMutedUsersIdsApiConfig,
  GetMutedUsersIdsApiRes,
  GetNotificationsApiConfig,
  GetNotificationsApiRes,
  GetPrivateEventsApiConfig,
  GetPrivateEventsApiRes,
  GetQuestionsApiConfig,
  GetQuestionsApiRes,
  GetReportedUsersByArtistApiConfig,
  GetReportedUsersByArtistApiRes,
  GetSalesLineGraphApiConfig,
  GetSalesLineGraphApiRes,
  GetSalesViewCountApiConfig,
  GetStreamInputsApiConfig,
  GetStreamInputsApiRes,
  GetTicketApiConfig,
  GetTicketApiRes,
  GetTicketsApiConfig,
  GetTicketsApiRes,
  GetToursApiConfig,
  GetToursApiRes,
  GetUserApiConfig,
  GetUserApiRes,
  GetUserPublicApiConfig,
  GetUserPublicRes,
  GetUsersWithPermissionsApiConfig,
  GetUsersWithPermissionsApiRes,
  LoginApiConfig,
  LoginApiRes,
  LogoutApiConfig,
  LogoutApiRes,
  PatchEventApiConfig,
  PatchEventApiRes,
  PatchUserApiConfig,
  PatchUserApiRes,
  PauseEventApiConfig,
  PauseEventApiRes,
  PostMuteUserApiConfig,
  PostMuteUserApiRes,
  PostUnmuteUserApiConfig,
  PostUnmuteUserApiRes,
  RefundAfterPartyApiConfig,
  RefundAfterPartyApiRes,
  RefundEventApiConfig,
  RefundEventApiRes,
  RegisterDeviceApiConfig,
  RegisterDeviceApiRes,
  ReportArtistUserApiConfig,
  ReportArtistUserApiRes,
  RequireAccessCodeCheckApiConfig,
  RequireAccessCodeCheckApiRes,
  RescheduleEventApiConfig,
  RescheduleEventApiRes,
  ResendNewGuestTicketApiConfig,
  ResetEventApiConfig,
  ResetEventApiRes,
  ResignApiConfig,
  ResignApiRes,
  SaveAddOnApiConfig,
  SaveAddOnApiRes,
  SearchAllApiConfig,
  SearchAllApiRes,
  SearchArtistsApiConfig,
  SearchArtistsApiRes,
  SearchCuratedEventsApiConfig,
  SearchCuratedEventsRes,
  SendNotificationEmailApiConfig,
  SetBundleOrderApiConfig,
  SetBundleOrderApiRes,
  SetBundlePublicApiConfig,
  SetBundlePublicApiRes,
  SetCartApiConfig,
  SetCartApiRes,
  SignUpApiConfig,
  SignUpApiRes,
  StartEventApiConfig,
  StartEventApiRes,
  SubmitFeedbackApiConfig,
  SubmitFeedbackApiRes,
  SuperuserRefreshEntityApiConfig,
  SwitchArtistBrandApiConfig,
  SwitchArtistBrandApiRes,
  ToggleQuestionsApiConfig,
  ToggleQuestionsApiRes,
  UpdateAccessCodeApiConfig,
  UpdateAccessCodeApiRes,
  UpdateBundleApiConfig,
  UpdateBundleApiRes,
  UpdateBundlePrintFul,
  UpdateBundlePrintFulApiConfig,
  UpdateEventApiConfig,
  UpdateEventApiRes,
  UpdateGeofenceApiConfig,
  UpdateMerchsApiConfig,
  UpdateMerchsApiRes,
  UpdateNotificationEmailApiConfig,
  UpdatePayMentMethodApiConfig,
  UpdatePayMentMethodApiRes,
  UpdateShopifyStoreApiConfig,
  UpdateShopifyStoreApiRes,
  UpdateTicketMasterApiConfig,
  UpdateTicketMasterApiRes,
  UpdateUserApiConfig,
  UpdateUserApiRes,
  UploadEventImageApiConfig,
  UploadImageApiRes,
  UploadUserImageApiConfig,
  VerifyDailyPassssworApiRes,
  VerifyDailyPasssswordApiConfig,
} from '@momenthouse/types'

import { t } from './endpointType'
import membershipEndpoints from './membershipEndpoints'
import socialEndpoints from './socialEndpoints'

export const apiEndpoints = {
  login: {
    method: 'post',
    url: '/api/v1/auth/login',
    config: t<LoginApiConfig, LoginApiRes>(),
  },
  logout: {
    method: 'post',
    url: '/api/v1/auth/logout',
    config: t<LogoutApiConfig, LogoutApiRes>(),
  },
  resign: {
    method: 'post',
    url: '/api/v1/auth/jwt/resign',
    config: t<ResignApiConfig, ResignApiRes>(),
  },
  signUp: {
    method: 'post',
    url: '/api/v1/users',
    config: t<SignUpApiConfig, SignUpApiRes>(),
  },
  checkEmail: {
    method: 'post',
    url: '/api/v1/users/has_account',
    config: t<CheckEmailApiConfig, CheckEmailApiRes>(),
  },
  verifyDailyPassword: {
    method: 'post',
    url: 'api/v1/superUser/verifyDailyPassword/:password',
    config: t<VerifyDailyPasssswordApiConfig, VerifyDailyPassssworApiRes>(),
  },
  changePassword: {
    method: 'post',
    url: 'api/v1/users/profile/update-password',
    config: t<ChangePasswordApiConfig, ChangePasswordApiRes>(),
  },
  setBundleOrder: {
    method: 'patch',
    url: 'api/v1/merchs/bundles/order',
    config: t<SetBundleOrderApiConfig, SetBundleOrderApiRes>(),
  },
  deleteBundle: {
    method: 'patch',
    url: 'api/v1/events/:eventIdOrSlug/bundles/delete',
    config: t<DeleteBundleApiConfig, DeleteBundleApiRes>(),
  },
  /* TODO: Remove old bundle endpoint when new bundle flow is fully released */
  deleteBundleNew: {
    method: 'delete',
    url: 'api/v1/merchs/bundle/:bundleId',
    config: t<DeleteBundleNewApiConfig, DeleteBundleNewApiRes>(),
  },
  updateBundlePrintFul: {
    method: 'patch',
    url: 'api/v1/events/:eventIdOrSlug/printful/bundles',
    config: t<UpdateBundlePrintFulApiConfig, UpdateBundlePrintFul>(),
  },
  deleteAddOn: {
    method: 'patch',
    url: 'api/v1/events/:eventIdOrSlug/bundles/delete',
    config: t<DeleteAddOnApiConfig, DeleteAddOnApiRes>(),
  },
  saveAddOn: {
    method: 'patch',
    url: 'api/v1/events/:eventIdOrSlug/bundles',
    config: t<SaveAddOnApiConfig, SaveAddOnApiRes>(),
  },
  createBundles: {
    method: 'post',
    url: 'api/v1/merchs/create_bundles',
    config: t<CreateBundlesApiConfig, CreateBundlesApiRes>(),
  },
  createBundle: {
    method: 'post',
    url: 'api/v1/merchs/bundle',
    config: t<CreateBundlesApiConfig, CreateBundlesApiRes>(),
  },
  updateBundle: {
    method: 'patch',
    url: 'api/v1/merchs/bundle/:bundleId',
    config: t<UpdateBundleApiConfig, UpdateBundleApiRes>(),
  },
  exportAddOnPurchase: {
    method: 'get',
    url: 'api/v1/merchs/:artistBrandId/event/:eventIdOrSlug/add_ons/csv',
    config: t<
      ExportExternalAddOnPurchaseApiConfig,
      ExportExternalAddOnPurchaseReqRes
    >(),
  },
  updateEvent: {
    method: 'patch',
    url: 'api/v1/events/:eventIdOrSlug',
    config: t<UpdateEventApiConfig, UpdateEventApiRes>(),
  },
  uploadEventImage: {
    method: 'post',
    url: 'api/v1/events/:eventIdOrSlug/image',
    config: t<UploadEventImageApiConfig, undefined>(),
  },
  uploadUserImage: {
    method: 'post',
    url: 'api/v1/users/image/:userId',
    config: t<UploadUserImageApiConfig, undefined>(),
  },
  uploadArtistImage: {
    method: 'post',
    url: 'api/v1/artist_brands/image/:artistBrandId',
    config: t<ApiConfig, undefined>(),
  },
  updateUser: {
    method: 'patch',
    url: 'api/v1/users/:userId',
    config: t<UpdateUserApiConfig, UpdateUserApiRes>(),
  },
  deleteOwnAccount: {
    method: 'delete',
    url: 'api/v1/users/account',
    config: t<DeleteOwnAccountApiConfig, DeleteOwnAccountApiRes>(),
  },
  confirmPayIntent: {
    method: 'post',
    url: 'api/v1/payment/intent',
    config: t<ConfirmPayIntentApiConfig, ConfirmPayIntentApiRes>(),
  },
  confirmMerchGalleryPayIntent: {
    method: 'post',
    url: 'api/v1/payment/merch_gallery/intent',
    config: t<
      ConfirmMerchGalleryPayIntentApiConfig,
      ConfirmMerchGalleryPayIntentApiRes
    >(),
  },
  confirmAddOnPayIntent: {
    method: 'post',
    url: 'api/v1/payment/add_on/intent',
    config: t<ConfirmAddOnPayIntentApiConfig, ConfirmAddOnPayIntentApiRes>(),
  },
  confirmClivePayIntent: {
    method: 'post',
    url: 'api/v1/payment/clive_seats/intent',
    config: t<ConfirmClivePayIntentApiConfig, ConfirmAddOnPayIntentApiRes>(),
  },
  confirmTippingPayIntent: {
    method: 'post',
    url: 'api/v1/payment/tipping/intent',
    config: t<
      ConfirmTippingPayIntentApiConfig,
      ConfirmTippingPayIntentApiRes
    >(),
  },
  getMerchs: {
    method: 'get',
    url: '/api/v1/artist_brands/:artistBrandId/merch',
    config: t<GetMerchsApiConfig, GetMerchsApiRes>(),
  },
  deleteMerchs: {
    method: 'delete',
    url: '/api/v1/merchs/:merchId',
    config: t<DeleteMerchApiConfig, DeleteMerchApiRes>(),
  },
  addMerchs: {
    method: 'post',
    url: '/api/v1/merchs',
    config: t<AddMerchsApiConfig, AddMerchsApiRes>(),
  },
  updateMerchs: {
    method: 'patch',
    url: '/api/v1/merchs/:merchId',
    config: t<UpdateMerchsApiConfig, UpdateMerchsApiRes>(),
  },
  updatePayMentMethod: {
    method: 'patch',
    url: '/api/v1/payment/payment_methods/:paymentMethodID/attach',
    config: t<UpdatePayMentMethodApiConfig, UpdatePayMentMethodApiRes>(),
  },
  deletePaymentMethod: {
    method: 'patch',
    url: '/api/v1/payment/payment_methods/:paymentMethodID/detach',
    config: t<DeletePayMentMethodApiConfig, DeletePayMentMethodApiRes>(),
  },
  updateMomentBackground: {
    method: 'post',
    url: '/api/v1/artist_brands/background-image/:artistBrandId',
    config: t<ApiConfig, undefined>(),
  },
  updateArtistBrand: {
    method: 'patch',
    url: '/api/v1/artist_brands/:artistBrandId',
    config: t<ApiConfig, undefined>(),
  },
  addShippingAddress: {
    method: 'post',
    url: 'api/v1/users/shipping_address',
    config: t<AddShippingAddressApiConfig, AddShippingAddressApiRes>(),
  },
  addDigitalAddress: {
    method: 'post',
    url: 'api/v1/users/digital/address',
    config: t<AddDigitalAddressApiConfig, AddDigitalAddressRes>(),
  },
  deleteShippingAddress: {
    method: 'delete',
    url: 'api/v1/users/shipping_address/:shippingAddressId',
    config: t<DeleteShippingAddressApiConfig, DeleteShippingAddressApiRes>(),
  },
  deleteMomentBackground: {
    method: 'delete',
    url: '/api/v1/artist_brands/background-image/:artistBrandId',
    config: t<ApiConfig, undefined>(),
  },
  getEvent: {
    method: 'get',
    url: 'api/v1/events/public/:eventIdOrSlug',
    config: t<GetEventApiConfig, GetEventApiRes>(),
  },
  getEventPrivate: {
    method: 'get',
    url: '/api/v1/events/private/:eventIdOrSlug',
    config: t<GetEventApiConfig, GetEventApiRes>(),
  },
  getTours: {
    method: 'get',
    url: '/api/v1/tours/artist_brands/:artistBrandId',
    config: t<GetToursApiConfig, GetToursApiRes>(),
  },
  testRedisEmitter: {
    method: 'post',
    url: 'api/v1/social/testRedisEmitter',
    config: t<ApiConfig, undefined>(),
  },
  registerDevice: {
    method: 'post',
    url: 'api/v1/devices',
    config: t<RegisterDeviceApiConfig, RegisterDeviceApiRes>(),
  },
  getCookies: {
    method: 'get',
    url: 'api/v1/devices/streams/:streamId/cookies',
    config: t<GetCookiesApiConfig, GetCookiesApiRes>(),
  },
  getUser: {
    method: 'get',
    url: 'api/v1/users/:userId',
    config: t<GetUserApiConfig, GetUserApiRes>(),
  },
  getUserPublic: {
    method: 'get',
    url: 'api/v1/users/:userId/public',
    config: t<GetUserPublicApiConfig, GetUserPublicRes>(),
  },
  getUsersWithPermissions: {
    method: 'get',
    url: '/api/v1/artist_brands/:artistBrandId/user-permissions',
    config: t<
      GetUsersWithPermissionsApiConfig,
      GetUsersWithPermissionsApiRes
    >(),
  },
  getTicket: {
    method: 'get',
    url: 'api/v1/devices/events/:eventId/tickets/:ticketId',
    config: t<GetTicketApiConfig, GetTicketApiRes>(),
  },
  getTickets: {
    method: 'get',
    url: '/api/v1/tickets/auth/get-tickets',
    config: t<GetTicketsApiConfig, GetTicketsApiRes>(),
  },
  submitFeedback: {
    method: 'post',
    url: 'api/v1/feedback/submit',
    config: t<SubmitFeedbackApiConfig, SubmitFeedbackApiRes>(),
  },
  patchUser: {
    method: 'patch',
    url: '/api/v1/users/:userId',
    config: t<PatchUserApiConfig, PatchUserApiRes>(),
  },
  getStreamInputs: {
    method: 'get',
    url: '/api/v1/events/streams/:streamId/inputs',
    config: t<GetStreamInputsApiConfig, GetStreamInputsApiRes>(),
  },
  startEvent: {
    method: 'post',
    url: '/api/v1/events/:eventId/start',
    config: t<StartEventApiConfig, StartEventApiRes>(),
  },
  resetEvent: {
    method: 'patch',
    url: '/api/v1/events/:eventId/reset_event',
    config: t<ResetEventApiConfig, ResetEventApiRes>(),
  },
  endEvent: {
    method: 'post',
    url: '/api/v1/events/:eventId/stop',
    config: t<EndEventApiConfig, EndEventApiRes>(),
  },
  pauseEvent: {
    method: 'post',
    url: '/api/v1/events/:eventId/intermission',
    config: t<PauseEventApiConfig, PauseEventApiRes>(),
  },
  patchEvent: {
    method: 'patch',
    url: '/api/v1/events/:eventId',
    config: t<PatchEventApiConfig, PatchEventApiRes>(),
  },
  getDemoSession: {
    method: 'get',
    url: '/api/v1/artist_brands/:artistBrandId/demo_session',
    config: t<GetDemoSessionApiConfig, GetDemoSessionApiRes>(),
  },
  getDemoSessionLeft: {
    method: 'get',
    url: '/api/v1/artist_brands/:artistBrandId/demo_session/time_left',
    config: t<GetDemoSessionLeftApiConfig, GetDemoSessionLeftApiRes>(),
  },
  endDemoSesson: {
    method: 'patch',
    url: '/api/v1/events/:eventId/demo_session/end',
    config: t<EndDemoSessonApiConfig, EndDemoSessonApiRes>(),
  },
  createVod: {
    method: 'post',
    url: '/api/v1/events/:eventId/createVOD',
    config: t<CreateVodApiConfig, CreateVodApiRes>(),
  },
  searchAll: {
    method: 'get',
    url: '/api/v1/search',
    config: t<SearchAllApiConfig, SearchAllApiRes>(),
  },
  searchArtists: {
    method: 'get',
    url: '/api/v1/artist_brands/getArtists/search',
    config: t<SearchArtistsApiConfig, SearchArtistsApiRes>(),
  },
  switchArtistBrand: {
    method: 'post',
    url: '/api/v1/artist_brands/switchArtistBrand',
    config: t<SwitchArtistBrandApiConfig, SwitchArtistBrandApiRes>(),
  },
  getUserPurchasedPhysicalGoods: {
    method: 'get',
    url: '/api/v1/merchs/auth/purchased-physical-goods',
    config: t<ApiConfig, undefined>(),
  },
  getPurchasedPhysicalGoods: {
    method: 'get',
    url: '/api/v1/merchs/purchased-physical-goods/:paymentInfoId',
    config: t<ApiConfig, undefined>(),
  },
  getArtistPublicEvents: {
    method: 'get',
    url: '/api/v1/artist_brands/:artistBrandId/public/events',
    config: t<GetArtistPublicEventsApiConfig, GetArtistPublicEventsApiRes>(),
  },
  getSalesLineGraph: {
    method: 'get',
    url: '/api/v1/sales/line_graph',
    config: t<GetSalesLineGraphApiConfig, GetSalesLineGraphApiRes>(),
  },
  getSalesViewCount: {
    method: 'get',
    url: '/api/v1/sales/view_counts',
    config: t<GetSalesViewCountApiConfig, GetSalesLineGraphApiRes>(),
  },
  addPhone: {
    method: 'post',
    url: '/api/v1/users/phoneNumbers',
    config: t<AddPhoneApiConfig, AddPhoneApiRes>(),
  },
  addMarketingEmail: {
    method: 'post',
    url: '/api/v1/users/marketingEmail',
    config: t<AddMarketingEmailApiConfig, AddMarketingEmailApiRes>(),
  },
  getDemos: {
    method: 'get',
    url: 'api/v1/generic/demos',
    config: t<GetDemoConfigsApiConfig, GetDemoConfigsApiRes>(),
  },
  refundEvent: {
    method: 'post',
    url: 'api/v1/events/:eventId/refund',
    config: t<RefundEventApiConfig, RefundEventApiRes>(),
  },
  rescheduleEvent: {
    method: 'post',
    url: 'api/v1/events/:eventId/reschedule',
    config: t<RescheduleEventApiConfig, RescheduleEventApiRes>(),
  },
  addSong: {
    method: 'post',
    url: 'api/v1/songlist',
    config: t<AddSongApiConfig, AddSongApiRes>(),
  },
  deleteSong: {
    method: 'delete',
    url: 'api/v1/songlist/:eventId/:songId',
    config: t<DeleteSongApiConfig, DeleteSongApiRes>(),
  },
  createVIPAfterParty: {
    method: 'post',
    url: 'api/v1/events/:eventId/vip?order=next',
    config: t<ApiConfig, undefined>(),
  },
  updateVIPAfterParty: {
    method: 'patch',
    url: 'api/v1/events/:eventId/vip?order=next',
    config: t<ApiConfig, undefined>(),
  },
  deleteVIPAfterParty: {
    method: 'delete',
    url: 'api/v1/events/:eventId/vip?order=next',
    config: t<ApiConfig, undefined>(),
  },
  getPrivateEvents: {
    method: 'get',
    url: 'api/v1/artist_brands/:artistBrandId/private/events',
    config: t<GetPrivateEventsApiConfig, GetPrivateEventsApiRes>(),
  },
  refundAfterParty: {
    method: 'post',
    url: 'api/v1/events/:eventId/refund',
    config: t<RefundAfterPartyApiConfig, RefundAfterPartyApiRes>(),
  },
  addNewGuest: {
    method: 'post',
    url: 'api/v1/tickets',
    config: t<AddNewGuestApiConfig, AddNewGuestApiRes>(),
  },
  resendNewGuestTicket: {
    method: 'post',
    url: 'api/v1/tickets/resend_guest_list_ticket',
    config: t<ResendNewGuestTicketApiConfig, undefined>(),
  },
  deleteGuest: {
    method: 'delete',
    url: 'api/v1/tickets/:ticketId',
    config: t<DeleteGuestApiConfig, undefined>(),
  },
  createAccessCode: {
    method: 'post',
    url: 'api/v1/access_codes',
    config: t<CreateAccessCodeApiConfig, CreateAccessCodeApiRes>(),
  },
  requireAccessCodeCheck: {
    method: 'patch',
    url: 'api/v1/events/:eventId',
    config: t<RequireAccessCodeCheckApiConfig, RequireAccessCodeCheckApiRes>(),
  },
  updateAccessCode: {
    method: 'patch',
    url: 'api/v1/access_codes',
    config: t<UpdateAccessCodeApiConfig, UpdateAccessCodeApiRes>(),
  },
  getCsvCodes: {
    method: 'get',
    url: 'api/v1/access_codes/:accessCodeGroupId/csv',
    config: t<GetCSVCodeApiConfig, undefined>(),
  },
  deleteAccessCode: {
    method: 'delete',
    url: 'api/v1/access_codes/:accessCodeGroupId',
    config: t<DeleteAccessCodeApiConfig, undefined>(),
  },
  countAccessCodes: {
    method: 'get',
    url: 'api/v1/access_codes/count',
    config: t<CountCodeApiConfig, CountCodeApiRes>(),
  },
  accessCodeDropDate: {
    method: 'patch',
    url: 'api/v1/access_codes/drop_date',
    config: t<AccessCodeDropDateApiConfig, undefined>(),
  },
  createGeofence: {
    method: 'post',
    url: 'api/v1/geofences/:eventId',
    config: t<CreateGeofenceApiConfig, CreateGeofenceApiRes>(),
  },
  updateGeofence: {
    method: 'patch',
    url: 'api/v1/geofences/:eventId/:geofenceId',
    config: t<UpdateGeofenceApiConfig, undefined>(),
  },
  deleteGeofence: {
    method: 'delete',
    url: 'api/v1/geofences/:eventId/:geofenceId',
    config: t<DeleteGeofenceApiConfig, DeleteGeofenceApiConfig>(),
  },
  getQuestions: {
    method: 'get',
    url: 'api/v1/events/:eventId/questions',
    config: t<GetQuestionsApiConfig, GetQuestionsApiRes>(),
  },
  toggleQuestions: {
    method: 'patch',
    url: 'api/v1/events/:eventId',
    config: t<ToggleQuestionsApiConfig, ToggleQuestionsApiRes>(),
  },
  addTierPricing: {
    method: 'post',
    url: 'api/v1/events/:eventId/tiers',
    config: t<AddTierApiConfig, AddTierApiRes>(),
  },
  deleteTier: {
    method: 'delete',
    url: 'api/v1/events/:eventId/tiers/:dateTime',
    config: t<DeleteTierApiConfig, undefined>(),
  },
  getEventNotifications: {
    method: 'get',
    url: 'api/v1/events/:eventId/notification',
    config: t<GetNotificationsApiConfig, GetNotificationsApiRes>(),
  },
  addNotificationEmail: {
    method: 'post',
    url: 'api/v1/events/:eventId/notification',
    config: t<AddNotificationEmailApiConfig, undefined>(),
  },
  updateNotificationEmail: {
    method: 'patch',
    url: 'api/v1/events/notification/:emailId',
    config: t<UpdateNotificationEmailApiConfig, undefined>(),
  },
  deleteNotificationEmail: {
    method: 'delete',
    url: 'api/v1/events/notification/:emailId',
    config: t<DeleteNotificationEmailApiConfig, undefined>(),
  },
  sendNotificationEmail: {
    method: 'post',
    url: 'api/v1/events/:eventId/sendTicketSalesReport',
    config: t<SendNotificationEmailApiConfig, undefined>(),
  },
  superuserRefreshEntity: {
    method: 'get',
    url: 'api/v1/superuser/refresh/:entityId',
    config: t<SuperuserRefreshEntityApiConfig, undefined>(),
  },
  runMigration: {
    method: 'get',
    url: 'api/v1/superuser/runMigration',
    config: t<ApiConfig, undefined>(),
  },
  // moderation get/post, mute/unmute
  addToModerationQueue: {
    method: 'post',
    url: 'api/v1/moderation',
    config: t<AddToModerationQueueApiConfig, AddToModerationQueueApiRes>(),
  },
  getMutedUsersIds: {
    method: 'get',
    url: 'api/v1/moderation/muted/users/ids',
    config: t<GetMutedUsersIdsApiConfig, GetMutedUsersIdsApiRes>(),
  },
  getMutedUsersEnriched: {
    method: 'get',
    url: 'api/v1/moderation/muted/users/enriched',
    config: t<GetMutedUsersEnrichedApiConfig, GetMutedUsersEnrichedApiRes>(),
  },
  postMuteUser: {
    method: 'post',
    url: 'api/v1/moderation/mute/user',
    config: t<PostMuteUserApiConfig, PostMuteUserApiRes>(),
  },
  postUnmuteUser: {
    method: 'post',
    url: 'api/v1/moderation/unmute/user',
    config: t<PostUnmuteUserApiConfig, PostUnmuteUserApiRes>(),
  },
  // ------------------
  setBundlePublic: {
    method: 'patch',
    url: '/api/v1/merchs/bundles/:bundleId/set_public',
    config: t<SetBundlePublicApiConfig, SetBundlePublicApiRes>(),
  },
  reportArtistUser: {
    method: 'post',
    url: 'api/v1/moderation/report/:artistBrandIdOrSlug/user/:reportUserIdOrSlug',
    config: t<ReportArtistUserApiConfig, ReportArtistUserApiRes>(),
  },
  getReportedUsersByArtist: {
    method: 'get',
    url: 'api/v1/moderation/report/:artistBrandIdOrSlug/user',
    config: t<
      GetReportedUsersByArtistApiConfig,
      GetReportedUsersByArtistApiRes
    >(),
  },
  getArtistsWithReportedUsers: {
    method: 'get',
    url: 'api/v1/moderation/chat/users/reported',
    config: t<
      GetArtistsWithReportedUsersApiConfig,
      GetArtistsWithReportedUsersApiRes
    >(),
  },
  getArtistsWithReportedUsersByArtistBrandIds: {
    method: 'get',
    url: 'api/v1/moderation/:artistBrandIds/chat/users/reported',
    config: t<ApiConfig, undefined>(),
  },
  getSpotifyAccessToken: {
    method: 'get',
    url: 'api/v1/auth/spotify/:profileId/token',
    config: t<ApiConfig, undefined>(),
  },
  getArtistBrand: {
    method: 'get',
    url: 'api/v1/artist_brands/:artistBrandIdOrSlug',
    config: t<GetArtistBrandApiConfig, GetArtistBrandApiRes>(),
  },
  getArtistBrandCreators: {
    method: 'get',
    url: 'api/v1/artist_brands/:artistBrandIdOrSlug/creators',
    config: t<GetArtistBrandCreatorsApiConfig, GetArtistBrandCreatorsApiRes>(),
  },
  getArtistBrandPublic: {
    method: 'get',
    url: '/api/v1/users/:userId/artist_brands',
    config: t<GetArtistBrandPublicApiConfig, GetArtistBrandPublicApiRes>(),
  },
  updateTicketMaster: {
    method: 'patch',
    url: '/api/v1/worlds/:worldId/ticketmaster',
    config: t<UpdateTicketMasterApiConfig, UpdateTicketMasterApiRes>(),
  },
  updateShopifyStore: {
    method: 'patch',
    url: '/api/v1/worlds/:worldId/shopifystore',
    config: t<UpdateShopifyStoreApiConfig, UpdateShopifyStoreApiRes>(),
  },
  addShopifyProduct: {
    method: 'post',
    url: '/api/v1/worlds/:worldId/shopifystore/:storeId/product',
    config: t<AddShopifyProductApiConfig, AddShopifyProductApiRes>(),
  },
  deleteShopifyProduct: {
    method: 'delete',
    url: '/api/v1/worlds/:worldId/shopifystore/:storeId/product',
    config: t<DeleteShopifyProductApiConfig, DeleteShopifyProductApiRes>(),
  },
  getCartCache: {
    method: 'get',
    url: '/api/v1/cart/:id',
    config: t<GetCartApiConfig, GetCartApiRes>(),
  },
  setCartCache: {
    method: 'post',
    url: '/api/v1/cart',
    config: t<SetCartApiConfig, SetCartApiRes>(),
  },
  forgotPassword: {
    method: 'post',
    url: '/api/v1/users/reset-password',
    config: t<ForgotPasswordApiConfig, ForgotPasswordApiRes>(),
  },
  getCurrencies: {
    method: 'get',
    url: 'api/v1/currencies/rates',
    config: t<getCurrenciesApiConfig, getCurrenciesApiRes>(),
  },
  getIdentity: {
    method: 'get',
    url: 'api/v1/patreon/:id/identity',
    config: t<ApiConfig, undefined>(),
  },
  generateEvTicket: {
    method: 'post',
    url: '/api/v1/tickets',
    config: t<GenerateEvTicketApiConfig, GenerateEvTicketApiRes>(),
  },
  searchCuratedEvents: {
    method: 'get',
    url: '/api/v1/events/search/curated/:language',
    config: t<SearchCuratedEventsApiConfig, SearchCuratedEventsRes>(),
  },
  getMoment: {
    method: 'get',
    url: 'api/v1/events/public/:eventIdOrSlug/watchpage',
    config: t<GetMomentApiConfig, GetMomentApiRes>(),
  },
  ...socialEndpoints,
  ...membershipEndpoints,
} as const

export default apiEndpoints
