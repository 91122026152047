// @ts-ignore TODO (temp ignore for mobile)
import { Quaternion, Vector3 } from 'three'

import { ArtistBrandProps, TicketmasterEventType, UserPublic } from '.'
import { AvatarProps } from './avatar'

export type ChatGroup = {
  description: string
  id: string
  image: string
  title: string
  creatorOwned?: boolean
  creatorOnly?: boolean
  tiers?: TierGatingProps
}

export interface TierGatingProps {
  allMembers?: boolean
  tierIds?: string[]
}

export enum NEUTRINO_CATALOG {
  OBSCENE = 'obscene',
  STRICT = 'strict',
}

// NOTE IF CHANGING HERE, CHANGE IN SERVER AS WELL
// server/src/db/models/World.ts
export interface WorldFlags {
  general: {
    requiredEmailVerification: boolean
    underConstruction: boolean
  }
  chat: {
    canSee: {
      superUser: boolean
      nonSuperUser: boolean
      none: boolean
    }
    canPost: {
      superUser: boolean
      nonSuperUser: boolean
      verifiedEmailOnly: boolean
      none: boolean
      minutesSinceAccountCreationToChat: number
    }
  }
  space: { enabled: boolean; nonCreatorCreate: boolean }
  board: {
    canSee: {
      superUser: boolean
      nonSuperUser: boolean
      none: boolean
    }
    canPost: {
      superUser: boolean
      nonSuperUser: boolean
      verifiedEmailOnly: boolean
      none: boolean
      minutesSinceAccountCreation: number
    }
    canDownvote: {
      superUser: boolean
      nonSuperUser: boolean
      verifiedEmailOnly: boolean
      none: boolean
    }
    canComment: {
      superUser: boolean
      nonSuperUser: boolean
      verifiedEmailOnly: boolean
      none: boolean
    }
    postNeutrinoCatalog: NEUTRINO_CATALOG | 'Off'
  }
  dms: {
    canSee: {
      superUser: boolean
      nonSuperUser: boolean
      none: boolean
    }
    canPost: {
      superUser: boolean
      nonSuperUser: boolean
      verifiedEmailOnly: boolean

      none: boolean
    }
  }
}

export interface WorldFeatured {
  enabled: boolean
  position: number
}

export interface WorldProps {
  id: string
  artistBrandId: string
  domain: string
  displayName: string
  description?: string
  detailImage?: string
  flags?: WorldFlags
  featured?: WorldFeatured
  logoBlack?: string
  logoWhite?: string
  landingPageBackgroundDesktop?: string
  landingPageBackgroundMobile?: string
  referralBackground?: string
  copyCustomization?: { [key: string]: string | number }
  colorCustomizations?: { highlight: string }
  createdAt: string
  creators?: Partial<UserPublic>[]
  hashtagRestricted?: boolean

  assets?: WorldAssetProps[]
  items?: WorldItemProps[]
  components?: WorldComponentProps[]

  artistBrand?: Partial<ArtistBrandProps>
  ticketmasterStore?: any
  ticketmasterStoreId?: string
  ticketmasterEvents?: TicketmasterEventType[]

  shopifyStore?: WorldShopifyStore
  shopifyStoreId?: string
  spotifyEnabled?: boolean

  musicAssets?: WorldMusicAsset[]

  lighting?: WorldLighting
  defaultAvatarConfigs?: {
    male: AvatarProps
    female: AvatarProps
  }
  games?: GameProps[]
  externalItems?: WorldExternalItemProps[]
  background?: string
  media?: WorldTheatreMediaProps
  backgroundMedia?: WorldBackgroundMediaProps

  backgroundAsset?: WorldAssetProps

  capacityCap: string
  capacityRedeemed: string
  /** world chat rooms */
  customChatrooms: {
    groups: ChatGroup[]
  }

  opengraph: {
    title: string
    description: string
    image: string
    links: any
  }

  timestamp?: string
  postImagesAllowed?: boolean
  directSignUpSource?: string
}
export interface WorldTheatreMediaProps {
  artistId?: string
  assets?: WorldMediaAsset[]
  domain?: string
  slug?: string
  itemId?: string
  today?: string
  startDate?: string
  endDate?: string
  livestreamUrl?: string
  livestreamTitle?: string
  livestreamSubtitle?: string
  livestreamImage?: string
  enableSchedule?: boolean
  availableEndingAt?: string
}

export interface WorldBackgroundMediaProps {
  worldId?: string
  assets?: WorldMediaAsset[]
  domain?: string
  slug?: string
}

export interface WorldMediaAsset {
  created?: string
  file?: string
  liveUrl?: string
  title?: string
  image?: string
  subtitle?: string
  order?: number
  timeInSeconds?: number
  startTime?: number
  enableSchedule?: boolean
  livestream?: boolean
  nonScheduled?: boolean // to play on loop before schedule
  onVideoEnd?: () => any
}

export interface WorldShopifyStore {
  id?: string
  logoImage?: string
  headerImage?: string
  title?: string
  description?: string
  domain?: string
  storefrontAccessToken?: string
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: any
  products?: WorldShopifyProduct[]
}

export interface WorldShopifyProduct {
  id?: string
  storeId?: string
  productId?: string
  customStyling?: any
  createdAt?: Date
  updatedAt?: Date
  deletedAt?: any
}

export interface WorldComponentProps {
  id?: string
  name?: string
  createdAt: string
  requiredFields?: { [key: string]: WorldComponentField }
  componentType?: string
}

export interface WorldComponentField {
  type: string
  default?: string | number | boolean
  max?: number
  min?: number
  order?: number
  options?: string[]
  asset?: WorldAssetProps
}

export interface VectorProps {
  x: number
  y: number
  z: number
}

export interface ItemTriggerProps {
  id?: string
  slug?: string
  misc?: any
}

export interface WorldItemModifier {
  assets: WorldAssetProps[]
  createdAt: string
  deletedAt: null | string
  id: string
  misc: {
    itemModifierProps: { [key: string]: WorldComponentField }
  }
  node: string
  name: string | null
  updatedAt: string
  worldComponentId: string
  worldId: string
  worldItemId: string
}

export interface WorldItemProps {
  id?: string
  worldId?: string
  name?: string
  slug?: string
  misc?: any
  worldComponentId?: string
  worldComponent?: Partial<WorldComponentProps>
  triggers?: Partial<ItemTriggerProps>[]
  actions?: Partial<ItemActionProps>[]
  assets?: Partial<WorldAssetProps>[]
  worldItemModifiers?: Partial<WorldItemModifier>[]

  // Represents the evaluated misc.requiredFields -> models, textures, etc
  transformedProps: any

  createdAt?: string
}

export interface WorldAssetProps {
  id?: string
  artistBrandId?: string
  worldId?: string
  name?: string
  assetType?: 'model' | 'normalMap' | 'texture'
  misc?: {
    mobilePath?: string // Mobile Optimized Path
    path?: string
    encoding?: number
    flipY?: boolean
    repeat?: boolean
    node?: string
    slug?: string
    file?: any // holds the actual file to be uploaded
  }
  createdAt?: string

  worldIds?: string[] // For Assets Table, can list worldIds that are associated. Should only be used in admin app, not cached well
  artistBrand?: ArtistBrandProps
  _tmpNameProperty?: string // used when uploading new components. Stores the component nameProperty on the asset temporarily
}

export interface FileProps {
  path: string
  flipY?: boolean
  repeat?: boolean
}

export interface ItemActionProps {
  id?: string
  slug?: string
  misc?: any
}

export interface WorldLighting {
  ambient: WorldBaseLighting
}

// https://threejs.org/docs/#api/en/lights/AmbientLight
export interface WorldBaseLighting {
  color?: string // hex color value, default #ffffff (white)
  intensity?: number // range from 0-1, default 1 for ambient light
}

export interface WorldMusicAsset {
  id?: string
  type?: string
  source?: string
  assetLink?: string
  worldId: string
}

export interface TicketMasterStore {
  createdAt?: string
  deletedAt?: string
  description?: string
  externalTicketmasterId: string
  headerImage?: string
  id?: string
  logoImage?: string
  title?: string
}

export interface OpenGraphLink {
  href?: string
  rel?: string
  sizes?: string
  type?: string
}

export interface WorldOpenGraph {
  title?: string
  description?: string
  links?: OpenGraphLink[]
  image?: string
}

export interface ServerMemoryInfo {
  rss: string
  heapTotal: string
  heapUsed: string // heap used in execution
  external: string // v8 memory
}

export interface ChallengeProps {
  id?: string
  name?: string
  worldId?: string
  info?: {
    duration?: number
  }
  isActive?: boolean
  createdAt?: string | null
  deletedAt?: string | null
}

export enum ActionType {
  animation = 'animation',
  visible = 'visible',
  sound = 'sound',
  chatBubble = 'chatBubble',
  repositioningAsset = 'repositioningAsset',
  custom = 'custom',
}

export enum RenderType {
  modal = 'modal',
  items = 'items',
}

export interface WorldExternalItemProps {
  id?: string
  artistBrandId?: string
  title?: string
  description?: string
  renderType?: RenderType
  misc?: {
    actionType?: ActionType
    items?: {
      id?: string
    }[]
    payload: {
      [key: string]: any
      default?: boolean
    }
  }
  image?: string
  createdAt?: string | null
  deletedAt?: string | null
}

export enum ProgressionActionType {
  create_board_post = 'create_board_post',
  edit_avatar = 'edit_avatar',
}

export enum ProgressionCapCadenceType {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  all_time = 'all_time',
}

export interface ProgressionActionProps {
  id?: string
  worldId?: string
  type?: ProgressionActionType
  minimumPoints?: number
  points?: number
  custom?: string
  capTimes?: number
  capCadence?: ProgressionCapCadenceType
  isActive?: boolean
  createdAt?: string | null
  updatedAt?: string | null
  deletedAt?: string | null
}

export interface RewardProps {
  id?: string
  name?: string
  artistBrandId?: string
  rewardType?: string
  misc?: {
    id?: string
    url?: string
    linkout?: string
  }
  description?: string | null
  createdAt?: string | null
  deletedAt?: string | null
}

export interface GameScoreProps {
  score: number
  userId: string
  username?: string
}

export interface GameProps {
  id?: string
  artistBrandId?: string
  gameType?: string
  misc?: {
    duration?: number
    returnToWorld?: boolean
  }
  description?: string
  name?: string
  isActive?: boolean
  createdAt?: string | null
  updatedAt?: string | null
  deletedAt?: string | null
}

export interface LeaderboardProps {
  date?: string
  gameScoreId?: string
  score?: number
  userId?: string
  username?: string
}

export type GameserverPlayer = {
  id: string
  username: string
  position: Vector3
  rotation: Quaternion
  status: string
}

export interface GameSocketPlayer {
  id: string
  username: string
  avatar?: AvatarProps
  position: number[]
  rotation: number[]
  status: string
  canBroadcast: boolean
  shadowFactor?: number
  spawned?: boolean
  playerSpot?: number
}

export type GameSocketTransformPayload = GameSocketPlayer & {
  forceUpdatePlayer?: boolean
}

export enum GameSocketClientMessage {
  broadcastAppearance = 'broadcastAppearance',
  connect = 'connect',
  reassign = 'reassign',
  spawn = 'spawn',
  login = 'login',
  logout = 'logout',
  transform = 'transform',
  avatarChange = 'avatarChange',
  disconnected = 'disconnected',
  disconnect = 'disconnect', // socket.io reserved
  removePlayer = 'removePlayer',
  pong = 'pong',
  ping = 'ping',
  health = 'health',
}
